.weather_container {
  display: flex;
  flex-direction: column;
  width: 305px; /* Adjust the width to match the container's width */
  height: 20px;
  opacity: 1;
  margin-top: auto;
  margin-left: auto;
}

.current_weather {
  color: white;
  width: 306px;
  max-height: 100px;
  display: flex;
  flex-direction: column;
  background-color: rgba(29, 90, 148, 0);
  cursor: pointer;
}

.current_weather:hover {
  background-color: #17616f49;
  opacity: 1;
}

.current_temp {
  font-size: 29px;
  color: rgb(231, 231, 231);
  margin: 20px;
}

.current_temp_hover {
  display: none;
}

.forecast_weather {
  display: flex;
  flex-direction: row;
  row-gap: 1px;
  color: white;
  background-color: #18758830;
  width: 305px; /* Ensure the forecasts don't overflow */
  overflow-x: auto; /* Add horizontal scroll if forecasts exceed the container width */
  padding: 1px; /* Add padding to prevent overflow */
}

.forecast_days {
  display: flex;
  margin-right: 0;
  overflow-x: auto; /* Allow horizontal scroll if forecasts exceed the container width */
}

.daily-forecast {
  flex: 0 0 auto; /* Ensure each forecast item doesn't grow or shrink */
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5px 8px;
  max-width: 43px; /* Set maximum width for each forecast item */
}

.daily-forecast p {
  margin: 0; /* Remove default margins */
}

.daily-forecast img {
  max-width: 40px; /* Set maximum width for weather icon */
}

.weather_container:hover .current_temp_hover {
  display: block;
  width: 305px;
  background-color: #18758830;
}

.thermo {
  color: crimson;
}

.blinking-arrow {
  display: inline-block;
  vertical-align: middle;
  margin-left: 20px;
  margin-bottom: 10px;
}

.blinking-arrow span {
  display: flex; /* Change display to inline-block */
  width: 7px;
  height: 6px;
  border-bottom: 4px solid #bad4e2;
  border-right: 4px solid #bad4e2;
  transform: rotate(45deg);
  margin: -0.8px;
  animation: animate 3.3s infinite;
}

.blinking-arrow span:nth-child(2) {
  animation-delay: 0.4s;
}

.blinking-arrow span:nth-child(3) {
  animation-delay: 0.6s;
}

@keyframes animate {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}
