.popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  /* background-color: rgba(255, 255, 255, 0.9); */
  background-color: rgba(233, 232, 231);
  max-width: 80%;
  max-height: 80%;
  padding: 20px;
  border-radius: 10px;
  overflow: hidden;
  z-index: 3;
}

.slick-slider {
  justify-content: center !important;
}

.slider-my-container {
  align-items: center;

  /* Center vertically */
  height: 350px;
  width: 450px;
}

.slider-image {
  width: 430px;
  margin-left: 10px;
  max-width: 100%;
  height: 350px;

  /* border-radius: 20px; */
}

.popup-close {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  font-size: 20px;
  color: #333;
}

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
}

.slick-arrow {
  color: rgb(9, 53, 229);
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 30px;
  width: 40px;
  height: 40px;
  line-height: 40px;
  cursor: pointer;
  z-index: 1000;
  background-color: rgb(9, 53, 229);
}

.slick-prev {
  left: 25px !important;

  /* background-color: black; */
  height: 30px;
  width: 30px;
  background: transparent !important;
  font-size: 30px;
}

.slick-next {
  right: 25px !important;
  height: 30px;
  width: 30px;
  background: transparent !important;
  font-size: 30px;
}

button.slick-arrow.slick-prev::before {
  content: '←';
  font-size: 30px;
}

button.slick-arrow.slick-next::before {
  content: '→';
  font-size: 30px;
}

.popup-inner {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
}

.other-images {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: flex-start;
  width: 320px;
}

.other-image {
  width: 57px;
  height: 60px;
  margin: 2px;
}

.room_close {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 450px;
  padding-left: 350px;
}

.slider_other_image {
  display: flex;
  flex-direction: row;
}

.close_btn {
  background-color: transparent;
  color: black;
  border: none;
  font-size: 20px;
}

.popup-content {
  max-height: 70vh;

  /* Set maximum height */
  overflow-y: scroll;

  /* Always show vertical scrollbar */
}

/* Style scrollbar for webkit browsers */
.popup-content::-webkit-scrollbar {
  width: 10px;
}

.popup-content::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 5px;
}

.popup-content::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0.1);
}

.popup-footer {
  display: flex;
  justify-content: flex-end;
  margin-top: -10px;
  padding-right: 15px;

  /* padding-bottom: 20px; */
}

.reserve-btn {
  /* margin-top: -20px; */
  background-color: black;
  color: white;
  padding: 8px 16px;
  border: none;
  cursor: pointer;
  font-style: italic;
  border-radius: 7px;
  font-size: 13px;
  box-shadow: 20px 20px 20px rgba(239, 238, 238, 0.1);
}

.reserve-btn:hover {
  background-color: #5ca1ff;
}

.other-image:hover {
  border: 2px solid #5ca1ff;
  box-shadow: inset 0 0 5px 0 rgba(0, 0, 0, 0.5);
}

.capacity_icons {
  display: flex;
  flex-direction: row;
}

.capacity_p {
  display: flex;
  flex-direction: row;
  gap: 2px;
}

/* @media (min-width: 425px) and (max-width: 767px) {
  .slider_other_image {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .room_close {
    padding-left: 0;
  }

  .popup-inner {
    padding: 10px;
  }

  .popup-footer {
    justify-content: center;
  }

  .reserve-btn {
    margin-top: 10px;
  }

  .other-images {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-content: flex-start;
    width: 84%;
  }
}

@media (min-width: 320px) and (max-width: 424px) {
  .popup{
    width: 95% !important;
  }
  .popup-content {
    max-height: 100vh;
    width: 95%;
  }

  .slider_other_image {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 80%;
  }

  .slider-my-container{
    width: 90%;
    height: 200px !important;
  }

  .slick-slider.slick-initialized{
    width: 90%;
    height: 200px !important;
  }

  .room_close {
    padding-left: 50px;
    font-size: 14px;
  }

  .popup-inner {
    padding: 15px;
  }

  .popup-footer {
    justify-content: center;
  }

  .reserve-btn {
    margin-top: 10px;
  }

  .other-images {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-content: flex-start;
    width: 84%;
  }
} */

@media screen and (min-width: 320px) and (max-width: 424px) {
  .popup {
    padding: 13px;
    margin-top: 7.5%;
    max-width: 85%;
    max-height: 80%;
  }

  .popup-content {
    max-width: 100%;
    margin-left: -32px;
    max-height: 55vh;
    margin-bottom: 3%;
  }

  .slider-my-container {
    display: flex;
    flex-direction: row;
    height: auto;
    width: 100%;
  }

  .slider_other_image {
    display: flex;
    flex-direction: column;
  }

  .slider-container {
    width: auto;
    height: auto;
  }

  .slick-list {
    width: 100%;
  }

  .slick-slider {
    width: 300px;
    height: 200px;
  }

  .slick-slider.slick-initialized {
    width: 92%;
    height: auto;
    margin-left: 5%;
  }

  .slider-image {
    width: 250px;
    height: auto;
    max-height: 220px !important;
  }

  .other-images {
    width: 90%;
    margin-left: 8%;
    margin-top: 10%;
    margin-bottom: 5%;
  }

  .icons_room {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
  }

  .other-image {
    width: 39px;
    height: 39px;
  }

  .room_close {
    width: 100%;
    padding-left: 2px;
  }

  .capacity_icons {
    flex-wrap: wrap;
    justify-content: left;
    margin-left: 30%;
  }

  .capacity_p {
    margin-left: 1%;
    margin-right: 40px;
    flex-direction: row;
  }

  /* Arrange div classes in two columns */
  .private_kitchen,
  .private_bathroom,
  .view,
  .room_facilities {
    display: flex;
    flex-direction: column !important;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 0 auto;
    width: 100%;
  }

  .description {
    margin: 0 auto;
    width: 100%;
  }

  .bed_icons {
    margin-left: 6%;
    margin-bottom: 20px;
  }
}

@media screen and (min-width: 425px) and (max-width: 767px) {
  .popup {
    padding: 13px;
    margin-top: 3%;
    max-width: 88%;
    height: 74.5%;
  }

  .slick-list {
    margin: 0 auto;
    height: auto;
    width: 97%;
  }

  .room_close {
    width: 100%;
    padding-left: 2px;
  }

  .slick-track {
    width: auto;
    height: 80%;
  }

  .slick-slider.slick-initialized {
    width: 100%;
    height: 90%;
  }

  .slick-prev {
    margin-left: 5%;
  }

  .slick-next {
    margin-right: 8%;
  }

  .slider-container {
    margin: 0 auto;
    width: 100% !important;
  }

  .slider-image {
    width: auto !important;
    height: auto !important;
    max-height: 360px;
    margin-left: auto !important;
  }

  .other-images {
    width: 90%;
    margin-left: 6%;
    margin-top: 2%;
    margin-bottom: 5%;
  }

  .other-image {
    width: 50px;
    height: 50px;
  }

  .icons_room {
    display: flex !important;
    flex-direction: column !important;
    flex-wrap: wrap !important;
    justify-content: space-between !important;
    width: 100% !important;
  }

  .popup-content {
    max-width: 100%;
    height: 70%;
    margin-left: -32px;
    max-height: 64vh;
  }

  .slider-my-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: auto !important;
  }

  .slider_other_image {
    display: flex;
    flex-direction: column;
  }

  .private_kitchen,
  .private_bathroom,
  .view,
  .room_facilities {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 0 auto;
    width: 100%;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .reserve-btn {
    position: relative;
    width: 110px;
    height: 40px;
    bottom: 25px;
    right: 5px;
  }

  .popup {
    margin-top: 2%;
    width: 75%;
    min-height: 65%;
    max-height: 70vh;
  }

  .popup-content {
    width: 85%;
    max-height: 61vh;
  }

  .slider_other_image {
    margin-left: 1%;
    width: 95%;
    display: flex;
    flex-direction: column;
  }

  .slick-prev {
    margin-top: -5%;
    margin-left: -1%;
  }

  .slick-next {
    margin-top: -5%;
    margin-right: 5%;
  }

  .other-images {
    margin-top: 5%;
    margin-left: 1%;
    width: 100%;
    margin-bottom: 5%;
  }

  .slider-container {
    margin: 0 auto;
    width: 100% !important;
  }

  .slider-my-container {
    width: 100%;
  }

  .slick-list {
    margin: 0 auto;
    height: auto;
    width: 97%;
  }

  .room_close {
    width: 100%;
    padding-left: 2px;
  }

  .slick-track {
    width: auto;
    height: 80%;
  }

  .description {
    width: 130%;
  }

  .private_kitchen {
    width: 140%;
    gap: 5% !important;
  }

  .private_bathroom {
    width: 140%;
    margin-right: 0%;
    gap: 5% !important;
  }

  .icons_room {
    gap: 20% !important;
    max-width: 50% !important;
    margin-right: 20% !important;
  }

  .view {
    width: 80%;
    margin-right: 0%;
    gap: 10%;
  }

  .private_kitchen_1 {
    min-width: 20%;
    margin-right: 10%;
  }

  .private_kitchen_2 {
    min-width: 22%;
    margin-right: 8%;
  }

  .private_kitchen_3 {
    min-width: 20% !important;
  }

  .private_bathroom_1 {
    min-width: 53% !important;
    margin-right: -50%;
  }

  .private_bathroom_2 {
    min-width: 20% !important;
    margin-right: -3%;
  }

  .private_bathroom_3 {
    min-width: 20% !important;
  }

  .view_1 {
    min-width: 44%;
    margin-right: -59%;
  }

  .view_2 {
    min-width: 43%;
    margin-right: -37%;
  }

  .view_3 {
    min-width: 35%;
  }

  .room_facilities {
    display: flex;
    flex-direction: column;
    width: 80% !important;
    gap: 10% !important;
  }

  .icons_room_0 {
    min-width: 80% !important;
    margin-right: 2%;
  }

  .icons_room_1 {
    min-width: 80% !important;
    margin-right: 2%;
  }

  .icons_room_2 {
    min-width: 80% !important;
  }

  .room_facilities_1 {
    min-width: 45%;
    margin-right: 8%;
  }

  .room_facilities_2 {
    min-width: 45%;
    margin-right: -8%;
  }

  .room_facilities_3 {
    min-width: 45%;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1439px) {
  .popup {
    margin-top: 2.5%;
  }

  .popup-content {
    max-height: 54vh;
  }
}

@media screen and (min-width: 1440px) and (max-width: 2559px) {
  .popup {
    margin-top: 1.5%;
  }

  .popup-content {
    max-height: 65vh;
  }
}
