.reservation-cards {
  display: flex;
  gap: 15px;
  margin-left: -10px;
  justify-content: center;
}

.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

.modal-content {
  width: 224px;
  height: 85px;
  background-color: rgba(255, 255, 255, 0.751);
  padding: 20px;
  border-radius: 5px;
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.logoB {
  width: 80px;
  height: 48px;
}

p {
  margin-top: auto;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.carousel-root {
  width: 385px;
  height: 485px;
}

.carousel.carousel-slider {
  border-radius: 12px;
  height: 485px;
}

.carousel .slide {
  display: flex;
  align-items: center;
}

.carousel.carousel-slider .control-arrow {
  background: darkcyan !important;
}

.carousel .carousel-status {
  color: transparent !important;
  text-shadow: none !important;
}

.menu {
  width: auto;
}

.reservation-container {
  width: 90%;
  height: auto;
  overflow-x: auto;
  white-space: nowrap;
  display: flex;
  justify-content: center;
  margin-top: 33px;
  margin-bottom: 33px;
  border-radius: 20px;
  padding: 0 20px;
}

.reservation-container::-webkit-scrollbar {
  width: 16px;
}

.reservation-container::-webkit-scrollbar-track {
  border-radius: 0;
  background-color: #eee0e0;
}

.reservation-container::-webkit-scrollbar-thumb {
  border-radius: 8px;
  border: 3px solid transparent;
  background-clip: content-box;
  background-color: #7da7d5;
}

.no-reservations {
  display: flex;
  justify-content: center;
  width: 100%;
}

.reservation-card {
  border: 1px solid #ccc;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 290px;
  background-color: #ebebeb;
  transition: transform 0.3s ease-in-out;
  margin-right: 20px;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 20px;
}

.reservation-card:hover {
  transform: translateY(-5px);
}

.reservation-card h2 {
  color: #27374d;
  font-size: 20px;
  margin-bottom: 8px;
  font-weight: bold;
}

.reservation-card p {
  margin: 4px 0;
  color: #27374d;
  font-size: 14px;
  line-height: 1.2;
}

.images {
  width: 100%;
  max-height: 150px;
  object-fit: cover;
  border-radius: 10px;
  margin-bottom: -10px;
}

table {
  margin: 0 auto;
  border-collapse: collapse;
  border-radius: 20px;
  margin-top: 15px;
}

th,
td {
  border: 1px solid #ebebeb;
  padding: 12px;
  color: #27374d;
  text-align: left;
}

th {
  color: #27374d;
  background-color: #ebebeb;
  font-weight: bold;
}

tr:nth-child(even) {
  color: #27374d;
  background-color: #ebebeb;
}

tr:nth-child(odd) {
  color: #27374d;
  background-color: #ebebeb;
}

tr:hover {
  background-color: #e1e1e1;
}

.button-container1 {
  /* margin-top: -35px;
  margin-bottom: -8px;
  margin-right: -9px; */
  justify-content: right;
}

.pay-button {
  /* margin-left: 200px; */
  text-align: center;
  background-color: #27374d;
  color: #fff;
  border: none;
  padding: 12px 16px;
  border-radius: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.pay-button:hover {
  background-color: #255f9a;
}

.stripe_button_delete {
  display: flex;
  flex-direction: row;
  gap: 70px;
  justify-content: center;
}

.delete {
  display: flex;
  flex-direction: row;
  gap: 3px;
  background-color: rgb(130, 20, 44);
  color: white;
  padding: 12px 16px;
  border: none;
  cursor: pointer;
  font-style: italic;
  border-radius: 7px;
  font-size: 13px;
  box-shadow: 20px 20px 20px rgba(239, 238, 238, 0.1);
}

@media only screen and (min-width: 320px) and (max-width: 767px) {
  .carousel .slide {
    height: 456px;
  }
}

@media only screen and (min-width: 320px) and (max-width: 424px) {
  .reservation-container {
    display: flex;
    flex-direction: column !important;
    align-items: center;
    overflow-y: auto !important;
    width: 86%;
    max-width: 424px;
    overflow-x: hidden;
  }

  .reservation-cards {
    display: flex;
    flex-direction: column;
  }

  .reservation-card {
    width: 250px;
    margin: 0;
  }

  .reservation-card h2 {
    font-size: 18px;
  }

  thead,
  tbody,
  th,
  td,
  tr {
    display: block;
  }

  table {
    width: 94%;
    max-width: 330px;
    margin-bottom: 20px;
  }

  tr { border: 1px solid #d2d2d2; }

  thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }

  td {
    border: none;
    border-bottom: 1px solid #ccc;
    position: relative;
    padding-left: 50%;
  }

  td::before {
    position: absolute;
    top: 6px;
    left: 6px;
    width: 45%;
    padding-right: 10px;
    white-space: nowrap;
  }

  .info_text p {
    color: red;
    font-size: 12px;
    font-weight: bold;
    margin-left: 10px;
    white-space: normal;
  }

  .info_text {
    display: flex;
    align-items: center;
    margin-top: 20px;
    color: red;
  }

  .carousel-root {
    width: 310px;
  }

  td:nth-of-type(1)::before { content: "Room Name:"; }
  td:nth-of-type(2)::before { content: "User:"; }
  td:nth-of-type(3)::before { content: "Start Date:"; }
  td:nth-of-type(4)::before { content: "End Date:"; }
  td:nth-of-type(5)::before { content: "Number of Nights:"; }
  td:nth-of-type(6)::before { content: "Total Price:"; }
  td:nth-of-type(7)::before { content: "Status:"; }
}

@media only screen and (min-width: 425px) and (max-width: 767px) {
  .reservation-container {
    display: flex;
    align-items: center;
    flex-direction: column !important;
    overflow-y: auto !important;
    width: 100%;
    max-width: 768px;
    padding: 0;
  }

  .reservation-cards {
    display: flex;
    flex-direction: column;
    margin-left: 0;
  }

  .reservation-card {
    width: 320px;
    max-width: 400px;
    margin: 0;
  }

  tr,
  thead,
  tbody,
  th,
  td {
    display: block;
  }

  table {
    width: 82%;
    margin-bottom: 20px;
  }

  tr { border: 1px solid #ccc; }

  thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }

  td {
    border: none;
    border-bottom: 1px solid #eee;
    position: relative;
    padding-left: 50%;
  }

  td::before {
    position: absolute;
    top: 6px;
    left: 6px;
    width: 45%;
    padding-right: 10px;
  }

  .info_text p {
    color: red;
    font-size: 12px;
    font-weight: bold;
    margin-left: 10px;
    white-space: normal;
  }

  .info_text {
    display: flex;
    align-items: center;
    margin-top: 20px;
    color: red;
  }

  .myreservations-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  td:nth-of-type(1)::before { content: "Room Name"; }
  td:nth-of-type(2)::before { content: "User"; }
  td:nth-of-type(3)::before { content: "Start Date"; }
  td:nth-of-type(4)::before { content: "End Date"; }
  td:nth-of-type(5)::before { content: "Number of Nights"; }
  td:nth-of-type(6)::before { content: "Total Price"; }
  td:nth-of-type(7)::before { content: "Reservation Status"; }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .reservation-cards {
    flex-wrap: wrap;
    justify-content: center;
    gap: 0;
  }

  .reservation-container {
    width: 500px;
    max-height: 519px;
    margin-left: -5px;
    padding: 0 0;
    justify-content: center;
  }

  .carousel-root {
    width: 482px;
    height: 519px;
  }

  .carousel.carousel-slider {
    height: 519px;
  }

  .reservation-container::-webkit-scrollbar {
    width: 0;
    height: 0;
  }

  .reservation-container::-webkit-scrollbar-track {
    border-radius: 0;
    background-color: transparent;
  }

  .reservation-container::-webkit-scrollbar-thumb {
    border-radius: 8px;
    border: 3px solid transparent;
    background-clip: content-box;
    background-color: rgba(125, 167, 213, 0.7);
    transition: background-color 0.3s;
  }

  .reservation-container:hover::-webkit-scrollbar {
    width: 16px;
    height: 16px;
  }

  .reservation-container:hover::-webkit-scrollbar-thumb {
    background-color: rgba(125, 167, 213, 0.7);
  }

  tr {
    font-size: 13.5px;
  }

  th,
  td {
    padding: 11px;
  }

  .reservation-card {
    width: 320px;
    margin-right: 0;
    margin-left: 0;
    margin-bottom: 5px;
    height: auto;
    justify-content: center;
  }

  .button-container1 {
    margin-left: -20px;
    width: 75%;
    margin-top: 5px;
  }

  .delete {
    margin-top: 5px;
  }

  .menu {
    width: 160px;
  }

  table {
    flex-wrap: wrap;
    margin-right: auto;
    margin-bottom: 20px;
    margin-top: 0;
    margin-left: auto;
  }

  th {
    color: #27374d;
    background-color: #ebebeb;
    font-weight: bold;
    padding: 9.5px;
  }

  p {
    font-weight: bold;
    font-size: 15px !important;
    margin-left: 5px;
  }

  .images {
    margin: 0 auto;
    margin-top: -7px;
    width: 320px;
    height: 300px;
    margin-bottom: 0;
  }

  .reservation-card h2 {
    font-size: 18px;
    margin-top: 1px;
    margin-bottom: 5px;
  }

  .pay-button {
    margin-left: 20px;
    text-align: center;
    background-color: #27374d;
    color: #fff;
    border: none;
    padding: 12px 16px;
    border-radius: 10px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }

  .info_text {
    display: flex;
    align-items: center;
    margin-top: 5px;
    color: red;
  }

  .info_text p {
    color: red;
    font-size: 12px;
    font-weight: bold;
    margin-left: 10px;
    white-space: normal;
  }

  .myreservations-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1439px) {
  .reservation-cards {
    gap: 1rem;
    width: 100%;
    margin-left: 0;
  }

  tr {
    font-size: 15px;
  }

  th,
  td {
    padding: 11px;
  }

  .reservation-card {
    width: 320px;
    margin-right: 0;
    margin-bottom: 5px;
    margin-top: 5px;
    height: auto;
    min-width: 239px;
    justify-content: center;
  }

  .carousel-root {
    width: 74vw;
  }

  .reservation-card h2 {
    font-size: 19px;
    margin-top: 1px;
    margin-bottom: 5px;
    padding: 0 0;
  }

  .reservation-container {
    width: 74vw;
    margin-left: auto;
    margin-right: auto;
    overflow-x: auto;
    padding: 0;
    border-radius: 0;
    gap: 1rem;
  }

  .button-container1 {
    margin-left: -20px;
    width: 75%;
    margin-top: 5px;
  }

  .delete {
    margin-top: 5px;
  }

  .pay-button {
    margin-left: 20px;
    text-align: center;
    background-color: #27374d;
    color: #fff;
    border: none;
    padding: 12px 16px;
    border-radius: 10px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }

  .carousel-group {
    display: flex;
    flex-direction: row;
    gap: 1rem;
  }

  .menu {
    width: 160px;
  }

  table {
    flex-wrap: wrap;
    margin-right: auto;
    margin-bottom: 20px;
    margin-top: 6px;
    margin-left: auto;
    width: 74vw;
  }

  th {
    color: #27374d;
    background-color: #ebebeb;
    font-weight: bold;
    padding: 9.5px;
  }

  p {
    font-weight: bold;
    font-size: 15px !important;
    margin-left: 5px;
  }

  .images {
    margin: 0 auto;
    margin-top: -7px;
    width: 100%;
    height: 300px;
    margin-bottom: 0;
  }

  .info_text p {
    color: red;
    font-size: 11px !important;
    font-weight: bold;
    margin-left: 10px;
    white-space: normal;
  }

  .info_text {
    display: flex;
    align-items: center;
    margin-top: 5px;
    color: red;
  }
}

@media only screen and (min-width: 1440px) {
  .reservation-cards {
    gap: 1rem;
    width: 100%;
    margin-left: 0;
  }

  .reservation-card {
    width: 320px;
    margin-right: 0;
    margin-bottom: 5px;
    margin-top: 5px;
    height: auto;
    justify-content: center;
  }

  .carousel-root {
    width: 1065.6px;
    height: 492px;
  }

  .carousel.carousel-slider {
    height: 492px;
  }

  .carousel .slider-wrapper.axis-horizontal .slider .slide {
    flex-flow: column;
  }

  .carousel-group {
    display: flex;
    flex-direction: row;
    gap: 1rem;
  }

  .reservation-container {
    width: 74vw;
    margin-left: auto;
    margin-right: auto;
    overflow-x: auto;
    padding: 0;
    border-radius: 0;
    gap: 1rem;
  }

  tr {
    font-size: 15px;
  }

  th,
  td {
    padding: 11px;
  }

  .info_text p {
    color: red;
    font-size: 12px;
    font-weight: bold;
    margin-left: 10px;
    white-space: normal;
  }

  .info_text {
    display: flex;
    align-items: center;
    margin-top: 5px;
    color: red;
  }

  table {
    width: 74vw;
  }
}
