.center-page {
  display: flex;
  justify-content: center;
  margin-top: 4vh;
}

.payment-success-wrapper {
  position: relative;
}

.payment-success-background {
  background-image: url(../images/Naslovna.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  filter: blur(1.5px); /* Adjust the blur radius as needed */
  z-index: 1; /* Ensure the header is below the container */
}

.payment-success-container {
  position: relative;
  max-width: 420px;
  color: #333;
  height: 477px;
  padding: 20px;
  background-color: rgba(247, 247, 247, 0.95); /* Adjust the background color and transparency */
  border: 2px solid #007bff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
  text-align: center;
  z-index: 1; /* Ensure the container is above the background */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.payment-success-title {
  font-size: 28px;
  font-weight: bold; /* Make the title bold */
  color: #007bff;
  margin-bottom: 20px;
}

.payment-success-info {
  font-size: 14px !important;
  color: #333;
  margin: 0;
  margin-bottom: 10px;
  margin-top: 10px;
}

.guest-name,
.room-name,
.room-id,
.reservation-id,
.user-id,
.email3,
.phone-number,
.country,
.start-date,
.end-date,
.nights-number,
.total-price {
  font-weight: bold;
}

.buttons-print-close {
  margin-top: 20px;
}

.print-button,
.close-button {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold; /* Make the button text bold */
  color: #fff;
  transition: background-color 0.3s ease;
}

.print-button {
  background-color: #2ecc71;
}

.close-button {
  background-color: #e74c3c;
}

.print-button:hover,
.close-button:hover {
  opacity: 0.8;
}

@media (max-width: 424px) {
  .payment-success-title {
    font-size: 28px;
    margin-bottom: -7px;
    margin-top: 0;
  }
}

@media (min-width: 425px) and (max-width: 768px) {
  .payment-success-container {
    height: 592px;
    width: 86vw;
    max-width: none;
  }

  .payment-success-title {
    font-size: 30px;
  }

  .payment-success-info {
    font-size: 16px !important;
    margin: 0;
    margin-bottom: 10px;
    margin-top: 10px;
  }

  .close-button {
    height: 50px;
    width: 108px;
    font-size: 24px;
  }

  .print-button {
    height: 50px;
    width: 108px;
    font-size: 24px;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .center-page {
    margin-top: 14vh;
  }

  .payment-success-container {
    height: 550px;
    width: 51vw;
    max-width: none;
  }

  .payment-success-title {
    font-size: 32px;
    margin-top: 0;
    margin-bottom: -7px;
  }

  .payment-success-info {
    font-size: 17px !important;
    margin: 0;
    margin-bottom: 10px;
    margin-top: 10px;
  }

  .close-button {
    height: 46px;
    width: 98px;
    font-size: 21px;
  }

  .print-button {
    height: 46px;
    width: 98px;
    font-size: 21px;
  }

  .check-icon {
    font-size: 65px;
  }
}

@media (min-width: 1024px) {
  .center-page {
    margin-top: 14vh;
  }

  .payment-success-container {
    height: 506px;
  }

  .payment-success-info {
    font-size: 14px !important;
    margin: 0;
    margin-bottom: 10px;
    margin-top: 10px;
  }
}

@media (min-width: 320px) and (max-width: 425px) {
  .payment-success-container {
    width: 302px;
    padding: 20px 0 20px 0;
  }
}
