.review-container {
  display: flex;
  padding-top: 12px;
  flex-direction: column;
  background-color: transparent;
  width: 100%;
  max-height: 350px;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 30px;
}

.title-rev {
  font-size: 15px;
  font-weight: bold;
  color: white;
}

.img {
  width: 50px;
  height: 50px;
}

.text-container {
  color: white;
}

.img-name {
  display: flex;
  flex-direction: row;
  column-gap: 15px;
  margin-top: 10px;
  margin-left: 15px;
}

.heart-container {
  font-size: 20px;
}

.heart-filled {
  color: red;
}

.heart-empty {
  color: lightgray;
}

.name-time {
  display: flex;
  flex-direction: column;
}

.name-time > p {
  margin: 5px 0;
}

.rev-but {
  display: flex;
  margin-right: auto;
  margin-left: auto;
  flex-direction: row;
  justify-content: space-between;
  width: fit-content;
}

.write-review-btn {
  margin-top: 8px;
  margin-right: auto;
  margin-left: auto;
  padding: 5px 10px;
  width: 110px;
  height: 20px;
  color: rgb(106, 106, 106);
  background-color: rgb(234, 234, 234);
  font-size: 15px;
  text-decoration: none;
  border-radius: 16px;
  margin-bottom: 10px;
  transition: background-color 0.3s, color 0.3s, border-color 0.3s;
}

.write-review-btn:hover {
  background-color: rgba(168, 210, 255, 0.9);
  color: rgb(28, 28, 28);
  border-color: black;
}

.review-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.review-card {
  background-color: rgba(60, 60, 60, 0.72);
  padding: 5px;
  border-radius: 12px;
  width: 400px;
  max-width: 400px;
  height: 235px;
  margin-top: 10px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 15px;
}

.review-card:hover {
  background-color: rgb(74, 74, 74, 0.9);
  color: black;
  border-color: black;
}

.review-wrapper .review-card:nth-child(1) {
  background-color: rgba(82, 81, 81, 0.55);
  backdrop-filter: blur(6px);
  padding: 15px;
  border-radius: 12px;
  margin-top: 30px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 15px;
}

.review-card .text-container {
  color: white;
  font-size: 17px;
  justify-content: center;
  text-align: justify;
}

.review-wrapper .review-card:nth-child(2) {
  background-color: rgba(63, 63, 63, 0.55);
  backdrop-filter: blur(6px);
  border-radius: 12px;
  height: 280px;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 15px;
}

.review-wrapper .review-card:nth-child(3) {
  background-color: rgba(63, 63, 63, 0.55);
  backdrop-filter: blur(6px);
  padding: 15px;
  border-radius: 12px;
  margin-top: 30px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 15px;
}

.review-wrapper .review-card:nth-child(2):hover {
  background-color: rgb(35, 35, 35, 0.75);
}

.review-wrapper .review-card:nth-child(3):hover {
  background-color: rgb(35, 35, 35, 0.7);
}

.review-wrapper .review-card:nth-child(1):hover {
  background-color: rgb(35, 35, 35, 0.7);
}

.review-card img {
  width: 55px;
  height: 55px;
  margin-bottom: 5px;
}

.review-card .heart-container {
  justify-items: center;
  font-size: 21px;
  margin-bottom: 15px;
}

.review-card .heart-filled {
  color: red;
}

.review-card .heart-empty {
  color: lightgray;
}

.review-card .img-name {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 10px;
}

.review-card .name-time {
  margin-left: 15px;
}

.review-card p {
  margin: 5px 0;
}

.review-card a {
  margin-top: 8px;
  margin-right: 5px;
  padding: 5px 10px;
  width: 120px;
  color: rgb(8, 39, 111);
  background-color: white;
  font-size: 14px;
  border: 2px solid rgb(8, 39, 111);
  text-decoration: none;
  border-radius: 10px;
  transition: background-color 0.3s, color 0.3s, border-color 0.3s;
}

.review-card a:hover {
  background-color: white;
  color: black;
  border-color: black;
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .review-container {
    background-image: none;
    max-height: none;
    max-width: 96%;
    margin-top: 20px;
    margin-bottom: 10px;
  }

  .review-card {
    width: 60%;
    max-width: 60%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 10px;
  }

  .review-card img {
    width: 35px;
    height: 35px;
  }

  .review-card .text-container {
    font-size: 13.7px;
    text-align: center;
  }

  .review-card .img-name {
    margin-top: 5px;
    margin-left: 10px;
    justify-content: center;
  }

  .review-card .name-time {
    margin-left: 10px;
  }

  .review-card .heart-container {
    margin-bottom: 10px;
  }

  .rev-but {
    flex-direction: column;
    align-items: center;
  }

  .write-review-btn {
    width: 87%;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .review-wrapper .review-card:nth-child(2) {
    margin-top: 18px;
    width: 120%;
    height: 270px;
    margin-right: 12px;
    max-width: 70%;
  }

  .review-wrapper .review-card:nth-child(1) {
    width: 70%;
    height: 220px;
    margin-right: 12px;
    max-width: 70%;
  }

  .review-wrapper .review-card:nth-child(3) {
    width: 70%;
    height: 220px;
  }
}

/* @media only screen and (max-width: 425px) {
  .review-container {
    max-width: 90%;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .review-card {
    width: 80%;
    max-width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 15px;
  }

  .review-card img {
    width: 35px;
    height: 35px;
  }

  .review-card .text-container {
    font-size: 12px;
    text-align: center;
  }

  .review-card .img-name {
    margin-top: 5px;
    margin-left: 5px;
    justify-content: center;
  }

  .review-card .name-time {
    margin-left: 5px;
  }

  .review-card .heart-container {
    margin-bottom: 10px;
  }

  .rev-but {
    flex-direction: column;
    align-items: center;
  }

  .write-review-btn {
    width: 87%;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .review-wrapper .review-card:nth-child(2) {
    width: 80%;
    margin-right: 0;
    margin-left: 0;
    max-width: 80%;
    height: 150px;
  }

  .review-wrapper .review-card:nth-child(1) {
    width: 80%;
    margin-right: 0;
    margin-left: 0;
    max-width: 80%;
    height: 150px;
  }

  .review-wrapper .review-card:nth-child(3) {
    width: 80%;
    max-width: 80%;
    height: 150px;
  }

  .review-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
} */
