.calendar-content {
  position: relative;
  padding: 10px;
  margin-top: auto;
}

.calendar-popup {
  width: 500px;
  margin-top: 20px;
  display: grid;
  gap: 5px;
}

.custom-calendar {
  border: none;
  background-color: #f7f7f6;
  border-radius: 8px;
  width: 100%;
  max-width: 600px;
  margin-top: 10px;
  margin-left: 5px;
}

.react-calendar__month-view__days {
  display: grid;
  gap: 5px;
}

.custom-calendar .react-calendar__month-view__days .react-calendar__tile {
  padding: 5px;
  width: 72.5px;
  height: 40px;
  min-width: 72.5px;
  min-height: 40px;
  max-width: 72.5px;
  max-height: 40px;
}

.available-date,
.unavailable-date {
  border-radius: 4px;
  color: rgb(48, 43, 43);
  padding: 5px;
  margin-bottom: 5px;
}

.available-date {
  background-color: #d4ffd8;
}

.unavailable-date {
  background-color: #ffd3d1;
}

@media screen and (min-width: 1024px) and (max-width: 1439px) {
  .custom-calendar .react-calendar__month-view__days .react-calendar__tile {
    min-width: 48.9px;
    max-width: 58.9px;
  }

  .react-calendar__month-view__weekdays {
    margin-right: 5px;
  }
}

@media screen and (min-width: 900px) and (max-width: 1023px) {
  .custom-calendar .react-calendar__month-view__days .react-calendar__tile {
    min-width: 72.5px;
    max-width: 76.5px;
  }
}

@media screen and (min-width: 425px) and (max-width: 900px) {
  .custom-calendar .react-calendar__month-view__days .react-calendar__tile {
    min-width: 42.5px;
    max-width: 44.5px;
  }

  .react-calendar__month-view__weekdays {
    margin-right: 5px;
  }
}

@media screen and (min-width: 376px) and (max-width: 424px) {
  .calendar-content {
    padding: 5px;
  }

  .custom-calendar .react-calendar__month-view__days .react-calendar__tile {
    width: 32px;
    min-width: 32px;
    max-width: 32px;
  }
}

@media screen and (min-width: 425px) and (max-width: 600px) {
  .custom-calendar .react-calendar__month-view__days .react-calendar__tile {
    min-width: 32.5px;
    flex: 0 !important;
  }

  .react-calendar__month-view__days {
    gap: 2.5px;
    margin-left: 1px;
  }

  .react-calendar__month-view__weekdays {
    font-size: 0.65em;
  }
}

@media screen and (min-width: 320px) and (max-width: 375px) {
  .calendar-content {
    position: absolute !important;
    bottom: 0 !important;
    left: 0 !important;
    right: 0 !important;
    padding: 10px !important;
  }

  .react-calendar__month-view__days {
    gap: 4px;
  }

  .custom-calendar .react-calendar__month-view__days .react-calendar__tile {
    width: 31.9px;
    min-width: 31.9px;
    max-width: 31.9px;
  }
}

@media screen and (min-width: 1440px) {
  .react-calendar__month-view__days {
    gap: 10px;
  }
}
