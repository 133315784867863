.entire-settings {
  display: flex;
  flex-direction: row;
}

.MainDash {
  overflow-y: hidden;
}

.profile-section {
  margin: 0 auto;
  background-color: #dde6edb4;
  padding: 20px;
  width: 50%;
  height: 460px;
  border-radius: 16px;
  margin-top: 10px;
}

.change_password {
  margin: 0 auto;
  padding: 20px;
  width: 50%;
  text-align: center;
  justify-content: center;
}

.profile-section label {
  text-align: left;
  color: #27374d;
}

.profile-section input[type="text"],
.profile-section input[type="email"],
.profile-section input[type="password"],
.profile-section input[type="file"] {
  display: flex;
  padding: 10px;
  margin-top: 9px;
  margin-bottom: 20px;
  color: #27374d;
  border: 1px solid #ccc;
  border-radius: 6px;
  margin-left: 0;
}

.profile-section input[type="file"] + .add_change_avatar {
  margin-top: 10px;
  display: flex;
  align-items: center;
}

.profile-section input[type="checkbox"] {
  margin-right: 5px;
}

.avatar-section {
  width: 5%;
}

.edit-profile-section {
  width: 30%;
}

.change-password-section {
  width: 30%;
  text-align: left;
}

.edit-password-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 20%;
}

.form-fields {
  padding: 20px;
  background-color: #fff;
  border-radius: 10px;
}

.name_edit,
.email_edit,
.phone_edit,
.country_edit,
.photo_edit,
.sec_old_pwd,
.sec_new_pwd,
.sec_conf_pwd {
  display: flex;
  flex-direction: column;
  margin-bottom: auto;
}

.form-fields label {
  display: block;
  margin-bottom: auto;
}

.form-fields input[type="text"],
.form-fields input[type="email"],
.form-fields input[type="password"],
.form-fields input[type="file"] {
  width: 98%;
  padding: 10px;
  margin-bottom: auto;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.form-fields input[type="file"] + .add_change_avatar {
  margin-top: 10px;
  display: flex;
  align-items: center;
}

.form-fields input[type="checkbox"] {
  margin-right: 5px;
}

.save_edit_button {
  background-color: #27374d;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-left: auto;
}

.save_pwd {
  display: block;
  background-color: #27374d;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: auto;
}

.save_edit_button:hover,
.save_pwd:hover {
  background-color: #255f9a;
}

.h2_pass1 {
  text-align: left;
  margin-bottom: 20px;
}

.sec_old_pwd {
  text-align: left;
  margin-bottom: 20px;
  color: #27374d;
}

.sec_new_pwd {
  margin-bottom: 20px;
  text-align: left;
  color: #27374d;
}

.sec_conf_pwd {
  margin-bottom: 10px;
  text-align: left;
  color: #27374d;
}

.change_password a {
  display: inline-block;
  justify-content: center;
  padding: 10px 20px;
  background-color: #27374d;
  color: #27374d;
  text-decoration: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 20px;
  transition: background-color 0.3s ease;
}

.change_password a:hover {
  background-color: #0056b3;
}

.form_change_pwd {
  background-color: #dde6edb4;
  height: 325px;
  padding: 20px;
  border-radius: 16px;
  margin-top: -10px;
}

.form_change_pwd input[type="password"] {
  background-color: #f0f8fc;
  width: 90%;
  padding: 10px;
  margin-top: 9px;
  margin-left: 0;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 6px;
}

.password-wrapper-1 {
  display: flex;
  align-items: center;
  position: relative;
}

.password-wrapper-1 input {
  flex: 1;
  width: 100%;
  padding: 10px;
  margin-top: 9px;
  margin-left: 0;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 6px;
  box-sizing: border-box;
}

.password-toggle-icon-1 {
  position: absolute;
  bottom: 2px;
  right: 30px;
  cursor: pointer;
  z-index: 2;
}

@media only screen and (min-width: 320px) and (max-width: 424px) {
  .MainDash {
    overflow-y: auto;
  }

  .entire-settings {
    display: flex;
    flex-direction: column;
    height: 100vh;
  }

  .profile-section {
    width: 78%;
    margin-bottom: 10px;
  }

  .change_password {
    width: 90%;
    margin-bottom: 10px;
  }
}

@media only screen and (min-width: 425px) and (max-width: 767px) {
  .MainDash {
    overflow-y: auto;
  }

  .entire-settings {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100vh;
  }

  .profile-section {
    width: 78%;
    margin-bottom: 10px;
  }

  .change_password {
    width: 85.2%;
    margin-bottom: 10px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .form-fields input[type="text"],
  .form-fields input[type="email"],
  .form-fields input[type="password"],
  .form-fields input[type="file"] {
    width: 90%;
  }

  .entire-settings {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
    margin: 0;
  }

  .profile-section {
    width: auto;
    height: 50%;
    margin: 0;
    margin-top: 20px;
  }

  .change_password {
    width: 100%;
    height: 50%;
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
    padding: 0;
  }

  .form_change_pwd {
    width: 100%;
    margin-top: 0;
    height: 100%;
  }
}

@media only screen and (min-width: 1024px) {
  .entire-settings {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    width: 74vw;
  }

  .profile-section {
    width: 50%;
    margin: 0 auto;
    margin-top: 20px;
    margin-bottom: 20px;
    height: auto;
  }

  .change_password {
    width: 50%;
    margin-top: 20px;
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
    padding: 0;
  }

  .form_change_pwd {
    width: 100%;
    margin-top: 0;
  }
}
