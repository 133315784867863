.MainDash {
  height: 100%;
}

.MainDash::-webkit-scrollbar {
  width: 16px;
}

.MainDash::-webkit-scrollbar-track {
  border-radius: 0;
  background-color: #eee0e0;
}

.MainDash::-webkit-scrollbar-thumb {
  border-radius: 8px;
  border: 3px solid transparent;
  background-clip: content-box;
  background-color: #7da7d5;
}

.content--header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #dde6ed94;
  padding: 20px;
  margin: 0 auto;
  border-bottom: 2px solid #ccc;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 20px;
  overflow: hidden;
  flex-direction: column;
}

.header--title {
  color: #27374d;
  overflow: hidden;
}

.content--info {
  position: static;
  display: flex;
  flex-direction: row;
  background-color: #e3ecf357;
  padding: 20px;
  width: auto;
  margin-top: 10px;
  margin-right: 20px;
  margin-left: 20px;
  box-shadow: 0 2px 4px rgba(58, 55, 55, 0.1);
  border-radius: 5px;
  overflow: hidden;
}

.info-left {
  padding: 40px;
  background-color: #b3cfde;
  border-radius: 16px;
  width: auto;
  height: auto;
  margin-top: 15px;
  margin-right: 5px;
  overflow: hidden;
}

.dashboard-image {
  margin-left: auto;
  margin-right: auto;
  cursor: pointer;
  height: 240px;
  width: 240px;
  border-radius: 50%;
  margin-bottom: 35px;
  overflow: hidden;
}

.info-right {
  padding: 5px;
  margin-left: 8px;
  margin-right: 0;
  margin-top: 15px;
  width: auto;
  height: auto;
  overflow: hidden;
}

.info-container {
  background-color: #b3cfde;
  margin-top: 15px;
  border-radius: 16px;
  padding: 20px;
  width: 400px;
  height: auto;
  margin-bottom: 30px;
}

.user-info1 {
  text-align: center;
  display: flex;
  font-size: 14px;
  flex-direction: column;
  margin-left: -18px;
  margin-bottom: 0;
  margin-top: -22px;
}

.user-info {
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
}

.bigger-label {
  color: #27374d;
  width: auto;
  min-width: 120px;
  font-weight: bolder;
  margin-bottom: 10px;
  margin-left: 10px;
  font-size: 16px;
}

.info-value1 {
  vertical-align: middle;
  padding: 6px;
  border-radius: 4px;
  width: auto;
  height: 20px;
  margin-left: 8px;
  font-size: 16px;
  font-weight: 900;
  color: #27374d;
}

.info-value {
  vertical-align: middle;
  padding: 0;
  border-radius: 4px;
  width: auto;
  height: 25px;
  margin-left: 10px;
  font-size: 18px;
  color: #3d4044;
}

.left-info-text {
  font-weight: bold;
  text-align: center;
  color: #27374d;
  font-size: 20px;
  margin-top: 5px;
  margin-left: -9px;
  margin-right: auto;
  width: auto;
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .MainDash {
    overflow-x: hidden;
    height: 100%;
    display: flex;
    justify-content: center;
    overflow-y: auto;
  }

  .MainDash::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  .MainDash::-webkit-scrollbar-track {
    background: transparent;
  }

  .MainDash::-webkit-scrollbar-thumb {
    background-color: #ccc;
    border-radius: 10px;
    border: 2px solid transparent;
  }

  .MainDash::-webkit-scrollbar-thumb:hover {
    background-color: #999;
  }

  .info-left {
    position: static;
    background-color: #e3ecf357;
    width: 100%;
    height: 33vh;
    box-shadow: 0 2px 4px rgba(58, 55, 55, 0.1);
    border-radius: 5px;
    overflow: hidden;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 194px;
  }

  .info-right {
    margin-left: 0;
    width: auto;
  }

  .info-container {
    position: static;
    display: flex;
    background-color: #e3ecf357;
    width: 100%;
    height: 320px;
    box-shadow: 0 2px 4px rgba(58, 55, 55, 0.1);
    border-radius: 5px;
    margin: 0;
    padding: 0;
  }

  .content--info {
    padding: 0;
    justify-content: center;
    margin: 0;
    margin-top: 20px;
    width: 63vw;
    gap: 1rem;
    background-color: transparent;
    box-shadow: none;
    height: 56vh;
    display: flex;
    flex-direction: column;
    min-height: 458px;
  }

  .info-value1 {
    height: auto;
    margin: 0;
    font-size: 14px;
  }

  .left-info-text {
    margin: 0;
    font-size: 13px;
  }

  .dashboard-image {
    display: flex;
    margin-bottom: 19px;
    aspect-ratio: 1/1;
    height: 16vh;
    width: auto;
    margin-top: 1vh;
    min-height: 132px;
  }

  .content--header {
    justify-content: center;
    padding: 0;
    width: 64vw;
    margin-left: auto;
    margin-right: auto;
    font-size: 17px;
  }

  .user-info1 {
    margin-left: 0;
  }

  .info-value {
    font-size: 13px;
  }

  .bigger-label {
    margin-bottom: 0;
    font-size: 14px;
  }

  .user-info {
    margin-bottom: 14px;
  }
}

@media only screen and (min-width: 1024px) {
  .header--title {
    margin: 1vw;
  }

  .content--header {
    justify-content: center;
    padding: 0;
    width: 74vw;
    margin-left: auto;
    margin-right: auto;
    font-size: 17px;
  }

  .content--info {
    padding: 0;
    justify-content: center;
    margin: 0;
    margin-top: 20px;
    width: 74vw;
    gap: 1rem;
    background-color: transparent;
    box-shadow: none;
    height: 52vh;
    min-height: 450px;
  }

  .info-container {
    position: static;
    display: flex;
    align-items: center;
    flex-direction: row;
    background-color: #e3ecf357;
    width: 70%;
    height: 100%;
    box-shadow: 0 2px 4px rgba(58, 55, 55, 0.1);
    border-radius: 5px;
    overflow: hidden;
    margin: 0;
    padding: 0;
  }

  .info-left {
    position: static;
    background-color: #e3ecf357;
    width: 30%;
    height: 100%;
    box-shadow: 0 2px 4px rgba(58, 55, 55, 0.1);
    border-radius: 5px;
    overflow: hidden;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .dashboard-image {
    margin: 0;
    aspect-ratio: 1/1;
    width: 14vw;
    height: auto;
    margin-top: -8vh;
  }

  .MainDash {
    overflow-x: hidden;
    width: 80.2vw;
    margin-left: 0.8vw;
    display: flex;
    justify-content: center;
    height: 100%;
    overflow-y: auto;
  }

  .user-info1 {
    margin: 0;
    padding: 1vw;
  }

  .info-value1 {
    margin: 0;
    font-size: 20px;
    padding: 0;
    height: auto;
  }

  .left-info-text {
    margin: 0;
    font-size: 19px;
  }

  .bigger-label {
    margin-bottom: 0;
    margin-top: 1vh;
    font-size: 21px;
  }

  .info-value {
    font-size: 22px;
  }
}

@media only screen and (min-width: 1440px) {
  .content--info {
    padding: 0;
    justify-content: center;
    margin: 0;
    margin-top: 20px;
    gap: 1rem;
    background-color: transparent;
    box-shadow: none;
    height: 70vh;
    min-height: 530px;
  }
}

@media only screen and (min-width: 320px) and (max-width: 424px) {
  .content--header {
    display: flex;
    justify-content: center;
    margin: 20px auto;
    padding: 10px !important;
    width: 80%;
  }

  .MainDash {
    width: 100%;
    max-width: 420px;
    padding-right: 0;
    padding-left: 5px;
  }

  .MainDash::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  .MainDash::-webkit-scrollbar-track {
    background: transparent;
  }

  .MainDash::-webkit-scrollbar-thumb {
    background-color: #ccc;
    border-radius: 10px;
    border: 2px solid transparent;
  }

  .MainDash::-webkit-scrollbar-thumb:hover {
    background-color: #999;
  }

  .myreservations-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .content--info {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
    width: 74%;
    max-width: 424px;
    border-radius: 16px;
    margin-bottom: 20px;
  }

  .info-container {
    background-color: transparent;
    width: 87%;
    max-width: 380px !important;
    margin-bottom: 10px;
  }

  .info-right {
    width: 100%;
    max-width: 380px !important;
    max-height: 350px;
    margin-bottom: 15px;
  }

  .info-left {
    width: 72%;
    background-color: transparent;
    max-width: 380px !important;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .left-info-text {
    margin: 0 auto;
  }

  .bigger-label {
    font-size: 15px;
  }

  .info-value {
    font-size: 16px;
  }

  .dashboard-image {
    width: 180px;
    height: 180px;
  }
}

@media only screen and (min-width: 425px) and (max-width: 767px) {
  .MainDash {
    height: auto;
    padding-right: 0;
    padding-left: 5px;
  }

  .MainDash::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  .MainDash::-webkit-scrollbar-track {
    background: transparent;
  }

  .MainDash::-webkit-scrollbar-thumb {
    background-color: #ccc;
    border-radius: 10px;
    border: 2px solid transparent;
  }

  .MainDash::-webkit-scrollbar-thumb:hover {
    background-color: #999;
  }

  .content--header {
    display: flex;
    justify-content: center;
    margin: 20px auto;
    padding: 10px !important;
    width: 82%;
  }

  .content--info {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
    width: 78%;
    max-width: 768px;
    height: 830px;
    margin-bottom: 20px;
  }

  .info-container {
    background-color: transparent;
    width: 88%;
    max-width: 560px !important;
    margin-bottom: 5px;
  }

  .info-right {
    width: 80%;
    max-width: 600px !important;
    max-height: 350px;
    margin-bottom: 1px;
  }

  .info-left {
    background-color: transparent;
    width: 80%;
    max-width: 530px !important;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .left-info-text {
    margin: 0 auto;
  }
}
