.nav-header {
  display: flex;
  align-items: center;
  height: 90px;
  padding: 0 2rem;
  transition: background-color 0.3s ease;
  position: fixed;
  top: 0;
  width: 100%;
  background-color: white;
  z-index: 1000;
  box-shadow: 0 6px 10px -4px rgba(0, 0, 0, 0.3);
}

.scrolled {
  background-color: rgba(255, 255, 255, 0.726);
}

.logo {
  height: 60px;
}

.navbar-nav .nav-link {
  margin: 0 1rem;
  color: rgb(26, 24, 185);
  text-decoration: none;
}

.navbar-nav .nav-link:hover {
  color: goldenrod;
}

.nav-header .nav-btn {
  padding: 5px;
  cursor: pointer;
  background: transparent;
  border: none;
  outline: none;
  color: gray;
  visibility: hidden;
  opacity: 0;
  font-size: 1.8rem;
}

.nav-header,
.navbar-nav {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
}

.phone-link-nav {
  margin-right: 10px;
  text-decoration: none;
  font-size: 15px;
  font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
  font-weight: bold;
  color: #1877f2;
}

.phone-nav:hover {
  transform: translateY(-2px);
}

.email-link-nav {
  margin-right: 10px;
  text-decoration: none;
  font-size: 17px;
  font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
  font-weight: bold;
  color: #1877f2;
}

.email-nav:hover {
  transform: translateY(-2px);
}

.facebook-icon-nav {
  padding-left: 0;
  margin-left: 550px;
  margin-right: 30px;
  color: #156ddf;
  font-size: 24px;
}

.facebook-icon-nav:hover {
  transform: translateY(-2px);
}

.phone-icon-nav,
.envelope-icon-nav {
  margin-right: 10px;
  color: #fc962b;
  font-size: 15px;
}

.location-icon-nav {
  padding-right: 0;
  margin-right: 500px;
  margin-left: 20px;
  color: #db4437;
  font-size: 24px;
  height: 30px;
  width: 35px;
}

.location-icon-nav:hover {
  transform: translateY(-2px);
}

.navigation-buttons {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.button-nav {
  display: inline-block;
  padding: 10px 15px;
  margin-bottom: 20px;
  width: 190px;
  height: 30px;
  border: none;
  border-radius: 30px;
  color: #fff;
  text-decoration: none;
  text-align: center;
  font-size: 20px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s;
  background-color: #007bff;
  box-shadow: 0 5px 15px rgba(0, 123, 255, 0.3);
}

.button-nav:hover {
  background-color: #0056b3;
  transform: translateY(-3px);
}

.button-nav:focus {
  outline: none;
}

.button-nav:active {
  transform: translateY(1px);
}

.button-logout-nav {
  display: inline-block;
  padding: 10px 15px;
  margin-bottom: 20px;
  width: 190px;
  height: 30px;
  border: none;
  border-radius: 30px;
  color: #fff;
  text-decoration: none;
  text-align: center;
  font-size: 20px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s;
  background-color: #768083;
  box-shadow: 0 5px 15px rgba(65, 67, 68, 0.3);
}

.button-logout-nav:hover {
  background-color: #454a50;
  transform: translateY(-3px);
}

.button-logout-nav:focus {
  outline: none;
}

.button-logout-nav:active {
  transform: translateY(1px);
}

@media only screen and (min-width: 320px) and (max-width: 424px) {
  .phone-hamburger-nav {
    margin-top: 100px !important;
  }

  .svg-inline--fa.fa-circle-user.fa-8x {
    max-width: 100px;
    max-height: 100px;
    margin-top: 30%;
    margin-bottom: 35%;
    margin-left: 10%;
  }
}

@media only screen and (max-width: 1023.98px) {
  .navbar-nav {
    display: block;
  }

  .nav-header {
    justify-content: space-between;
  }

  .nav-header .nav-btn {
    visibility: visible;
    opacity: 1;
    margin-right: 3.5rem;
  }

  .nav-header .logo {
    margin-left: 0;
  }

  .nav-header .nav-btn.nav-close-btn {
    margin-right: 0;
  }

  .nav-header .navbar-nav {
    position: fixed;
    top: 0;
    right: 0;
    height: 100%;
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 1;
    gap: 1.5rem;
    background-color: rgb(241, 243, 250);
    transition: 1s;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.5);
    -moz-box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.5);
  }

  .nav-header .responsive_nav {
    transform: translateX(100%);
  }

  .navbar-nav .nav-close-btn {
    position: absolute;
    top: 2rem;
    right: 2rem;
  }

  .navbar-nav .nav-link {
    font-size: 1.5rem;
  }

  .phone-hamburger-nav {
    margin-top: 200px;
    padding: 5px;
    margin-left: 30px;
    text-decoration: none;
    font-size: 15px;
    font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
    font-weight: bold;
    color: #1877f2;
  }

  .phone-hamburger-nav:hover {
    transform: translateY(-2px);
  }

  .email-hamburger-nav {
    padding: 5px;
    text-decoration: none;
    font-size: 17px;
    font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
    font-weight: bold;
    color: #1877f2;
  }

  .email-hamburger-nav:hover {
    transform: translateY(-2px);
  }

  .phone-hamburger-icon-nav,
  .envelope-hamburger-icon-nav {
    margin-right: 15px;
    color: #fc962b;
    font-size: 15px;
  }

  .icons-hmaburger-nav {
    margin-top: 10px;
  }

  .facebook-hamburger-icon-nav {
    margin-bottom: 5px;
    margin-left: 40px;
    color: #156ddf;
    font-size: 24px;
  }

  .facebook-hamburger-icon-nav:hover {
    transform: translateY(-2px);
  }

  .location-hamburger-icon-nav {
    margin-left: 60px;
    color: #db4437;
    font-size: 24px;
    height: 30px;
    width: 35px;
  }

  .location-hamburger-icon-nav:hover {
    transform: translateY(-2px);
  }
}

@media (min-width: 1024px) and (max-width: 1277px) {
  .navbar-nav {
    display: flex;
  }

  .facebook-icon-nav {
    margin-left: 180px;
  }

  .location-icon-nav {
    margin-right: 100px;
  }
}

@media (min-width: 1278px) and (max-width: 1440px) {
  .facebook-icon-nav {
    margin-left: 300px;
  }

  .location-icon-nav {
    margin-right: 250px;
  }
}

@media (min-width: 1441px) and (max-width: 1700px) {
  .facebook-icon-nav {
    margin-left: 400px;
  }

  .location-icon-nav {
    margin-right: 300px;
  }
}

@media (min-width: 1700px) and (max-width: 1900px) {
  .facebook-icon-nav {
    margin-left: 500px;
  }

  .location-icon-nav {
    margin-right: 400px;
  }
}

@media (min-width: 1901px) and (max-width: 2100px) {
  .facebook-icon-nav {
    margin-left: 550px;
  }

  .location-icon-nav {
    margin-right: 450px;
  }
}

@media (min-width: 2101px) and (max-width: 2560px) {
  .facebook-icon-nav {
    margin-left: 700px;
  }

  .location-icon-nav {
    margin-right: 500px;
  }
}
