#bookBtn {
  background-color: black;
  color: white;
  margin-bottom: -15px;
  padding: 8px 16px;
  border: none;
  cursor: pointer;
  font-style: italic;
  border-radius: 7px;
  font-size: 13px;
  box-shadow: 20px 20px 20px rgba(239, 238, 238, 0.1);
}

#detailsBtn {
  padding: 8px 16px;
  margin-right: 15px;
  margin-bottom: -15px;
  font-size: 13px;
  border-radius: 7px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  background-color: rgb(93, 160, 255);
  color: rgb(244, 243, 243);
}

@media (min-width: 320px) and (max-width: 424px) {
  #detailsBtn {
    margin-top: 10px;
  }

  #bookBtn {
    margin-top: 10px;
  }

  .cardmedia-room {
    margin-left: 50px;
  }

  /* .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.MuiCard-root.custom-card.css-gcr92j-MuiPaper-root-MuiCard-root {
    width: 300px !important;
    margin-left: 10px !important;
  } */

  .card-content-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    position: relative;
  }

  /* .MuiCardMedia-root.cardmedia-card.css-1cjpyng-MuiCardMedia-root {
    width: 310px !important;
  } */
}

@media (min-width: 425px) and (max-width: 767px) {
  #detailsBtn {
    margin-top: 10px;
  }

  #bookBtn {
    margin-top: 10px;
  }

  /* .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.MuiCard-root.custom-card.css-gcr92j-MuiPaper-root-MuiCard-root {
    margin-left: 10px !important;
    width: 400px !important;
  } */

  .card-content-container {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  /* .MuiCardMedia-root {
    display: flex !important;
    flex-direction: column !important;
    flex-wrap: nowrap !important;
    justify-content: center !important;
  }

  .MuiCardMedia-root.cardmedia-card.css-1cjpyng-MuiCardMedia-root {
    width: auto !important;
  } */

  .custom-card {
    width: 90%;
  }
}

@media (min-width: 767px) and (max-width: 900px) {
  .card-content-container {
    flex-direction: column;
  }
}
