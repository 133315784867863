.sidebar {
  display: flex;
  flex-direction: column;
  top: 0;
  bottom: 0;
  transition: all 300ms ease;
}

.avatar-image {
  cursor: pointer;
  border-radius: 50%;
  margin-top: 20px;
  margin-left: 45px;
}

/* logo */
.bars {
  display: none;
}

.logo-sidebar {
  display: flex;
  height: 5rem;
  font-weight: bold;
  font-size: 22px;
  gap: 1rem;
  align-items: center;

  /* justify-content: center; */
  margin-left: 2rem;
  margin-bottom: 20px;
  color: #27374d;
}

.logo-icon {
  height: 50px;
  width: 50px;
  margin-left: 10px;
}

/* menu */
.menu {
  margin-top: 4rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.menuItem {
  display: flex;
  align-items: center;
  gap: 0;
  height: 2.5rem;
  margin-left: 2rem;
  position: relative;
  transition: all 300ms ease;
  border-radius: 0.7rem;
  font-size: 16px;
  text-decoration: none;
  font-weight: bold;
  color: #27374d;
}

.menu-icon {
  height: 300px;
  width: 30px;
}

.menuItem:hover {
  cursor: pointer;
}

.menu .menuItem:nth-last-child(2) {
  position: absolute;
  bottom: 5rem;
}

.menu .menuItem:last-child {
  position: absolute;
  bottom: 3rem;
  width: 100%;
}

.active {
  background: var(--activeItem);
  margin-left: 0;
  margin-right: 10px;
}

.active::before {
  content: "";
  width: 8px;
  height: 100%;
  background: var(--blue);
  margin-right: calc(1rem - 8px);
}

/* Tablets */
@media (min-width: 1024px) {
  .sidebar {
    overflow: hidden;
    padding: 0;
    border-right: 1px solid rgba(169, 200, 209, 0.589) !important;
  }

  .avatar-image-box {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .avatar-image {
    margin-top: -25px;
    margin-bottom: -50px;
    margin-left: 0;
    aspect-ratio: 1/1;
    height: auto;
    width: 11vw;
  }

  .logo-sidebar {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 2vh;
    gap: 0;
    margin-left: 0;
  }

  .logo-icon {
    margin-right: 5px;
    flex-shrink: 0;
    height: 30px;
    width: 30px;
    margin-left: 0;
  }

  .username {
    word-wrap: break-word;
    font-size: 1.5vw;
  }

  .menu-icon {
    height: 23px;
    aspect-ratio: 1/1;
    width: auto;
    margin-left: 34px;
  }

  .menuItem {
    gap: 5px;
    border-radius: 0.7rem;
    margin-left: 0;
    height: 2.5rem;
    font-size: 13px;
  }

  .active::before {
    margin-right: -13px;
    margin-left: 0;
  }

  .menu {
    margin-top: 3rem;
    display: flex;
    flex-direction: column;
    gap: 1vh;
    width: 100%;
  }

  .menuItem:last-child {
    width: auto !important;
    margin-left: 31px;
  }

  .menu .menuItem:nth-last-child(2) {
    bottom: 6.3rem;
    margin-left: 31px;
  }
}

@media screen and (min-width: 375px) and (max-width: 425px) {
  .sidebar {
    left: -67%;
  }
}

@media screen and (min-width: 425px) and (max-width: 767px) {
  .sidebar {
    position: fixed;
    height: 100%;
    z-index: 9;
    background: linear-gradient(106.37deg, #ffe1bc 29.63%, #52f3bd 51.55%, #3437d8 90.85%);
    width: 55%;
    padding-right: 1rem;
  }

  .menuItem > span {
    display: flex;
  }

  .logo-sidebar {
    display: flex !important;
    justify-content: center;
    margin-left: -12.5%;
    margin-bottom: -6%;
  }

  .avatar-image-box {
    display: flex;
    justify-content: center;
  }

  .avatar-image {
    cursor: pointer;
    border-radius: 50%;
    margin: 7% 2rem auto;
    margin-bottom: -70px;
  }

  .menu-icon {
    height: auto;
  }

  .menu .menuItem:nth-last-child(5) {
    margin-bottom: -5%;
  }

  .menu .menuItem:nth-last-child(4) {
    margin-bottom: -5%;
  }

  .menu .menuItem:nth-last-child(3) {
    margin-bottom: -5%;
  }

  .menu .menuItem:nth-last-child(2) {
    position: absolute;
    bottom: 4.8rem;
  }

  .menu .menuItem:last-child {
    position: absolute;
    bottom: 2rem;
    width: 100%;
  }

  .bars {
    display: flex;
    position: fixed;
    top: 2rem;
    left: 60%;
    background: #7bc3ec;
    padding: 10px;
    border-radius: 10px;
    z-index: 10;
  }

  .close {
    left: -60%;
  }
}

@media screen and (min-width: 375px) and (max-width: 424px) {
  .sidebar {
    position: fixed;
    z-index: 9;
    height: 100%;
    background: linear-gradient(106.37deg, #ffe1bc 29.63%, #52f3bd 51.55%, #3437d8 90.85%);
    padding-right: 1rem;
  }

  .avatar-image {
    cursor: pointer;
    border-radius: 50%;
    margin: 8% 12% auto;
    margin-bottom: -75px;
  }

  .menu-icon {
    height: auto;
  }

  .menuItem > span {
    display: block;
  }

  .logo-sidebar {
    display: flex;
    margin-left: 10%;
    margin-bottom: -15%;
  }

  .menu .menuItem:nth-last-child(5) {
    margin-bottom: -9%;
  }

  .menu .menuItem:nth-last-child(4) {
    margin-bottom: -9%;
  }

  .menu .menuItem:nth-last-child(3) {
    margin-bottom: -9%;
  }

  .menu .menuItem:nth-last-child(2) {
    bottom: 2.6rem;
  }

  .menu .menuItem:last-child {
    bottom: 0.6rem;
  }

  .bars {
    display: flex;
    position: fixed;
    top: 2rem;
    left: 60%;
    background: #7bc3ec;
    padding: 10px;
    border-radius: 10px;
    z-index: 10;
  }

  .close {
    left: -60%;
  }
}

@media screen and (min-width: 320px) and (max-width: 375px) {
  .sidebar {
    position: fixed;
    z-index: 9;
    height: 100%;
    background: linear-gradient(106.37deg, #ffe1bc 29.63%, #52f3bd 51.55%, #3437d8 90.85%);
    width: 55%;
    padding-right: 1rem;
  }

  .avatar-image {
    cursor: pointer;
    border-radius: 50%;
    margin: 8% 12% auto;
    margin-bottom: -75px;
  }

  .menu-icon {
    height: auto;
  }

  .menuItem > span {
    display: block;
  }

  .logo-sidebar {
    display: flex;
    margin-left: 7%;
    margin-bottom: -18%;
  }

  .menu .menuItem:nth-last-child(5) {
    margin-bottom: -9%;
  }

  .menu .menuItem:nth-last-child(4) {
    margin-bottom: -9%;
  }

  .menu .menuItem:nth-last-child(3) {
    margin-bottom: -9%;
  }

  .menu .menuItem:nth-last-child(2) {
    bottom: 2.7rem;
  }

  .menu .menuItem:last-child {
    bottom: 0.7rem;
  }

  .bars {
    display: flex;
    position: fixed;
    top: 2rem;
    left: 60%;
    background: #7bc3ec;
    padding: 10px;
    border-radius: 10px;
    z-index: 10;
  }

  .close {
    left: -60%;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .sidebar {
    overflow: hidden;
    padding: 0;
  }

  .avatar-image-box {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .avatar-image {
    margin-top: -16px;
    margin-bottom: -50px;
    margin-left: 0;
    aspect-ratio: 1/1;
    height: auto;
    width: 18vw;
  }

  .logo-sidebar {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 2vh;
    gap: 0;
  }

  .logo-icon {
    margin-right: 5px;
    flex-shrink: 0;
    height: 30px;
    width: 30px;
    margin-left: 0;
  }

  .username {
    word-wrap: break-word;
    font-size: 2vw;
  }

  .menu-icon {
    height: 30px;
    width: 30px;
    margin-left: 34px;
  }

  .menuItem {
    gap: 5px;
    font-size: 1.8vw;
    width: 93%;
    border-radius: 0.7rem;
    margin-left: 0;
  }

  .active::before {
    margin-right: -13px;
    margin-left: 0;
  }

  .menu {
    margin-top: 3rem;
    display: flex;
    flex-direction: column;
    gap: 1vh;
    width: 100%;
  }

  .menuItem:last-child {
    width: auto !important;
    margin-left: 31px;
  }

  .menu .menuItem:nth-last-child(2) {
    bottom: 6.3rem;
    margin-left: 31px;
    width: auto;
  }
}
