.about-me {
  width: 17%;
  margin-left: auto;
  margin-right: auto;
  margin-top: auto;
}

.about-section {
  background-color: #0f254e;
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: static;
  padding: 28px;
  margin-top: auto;
  margin-bottom: 40px;
}

.google-maps-widget {
  margin-right: auto;
  margin-left: auto;
}

.google-maps-widget iframe {
  border-radius: 10px;
  border-color: black;
  margin-right: 10%;
  margin-bottom: 10px;
  width: 280px;
  height: 160px;
  border: 0;
}

.about-logo {
  margin-left: -15px;
  margin-right: 0;
  display: flex;
  height: auto;
  width: 190px;
  justify-content: center;
  margin-bottom: 15px;
}

.about-text {
  margin-left: -10px;
  text-align: left;
  font-weight: bold;
  font-style: italic;
  color: #fff;
  word-spacing: 1px;
  line-height: 1.6;
}

.contact {
  margin-top: 10px;
  font-weight: bolder;
  color: #fff;
  margin-bottom: 10px;
}

.phone-footer {
  color: white;
  text-decoration: none;
}

.email-footer {
  color: white !important;
  text-decoration: none;
}

.footer-logo {
  margin-bottom: -6px;
  width: 30px;
  height: 30px;
}

.tablet-box {
  display: flex;
  flex-direction: row;
}

.room1 {
  display: inline-block;
  font-style: oblique;
  margin-left: 5px;
  margin-top: 20px;
  margin-bottom: 5px;
  color: #fff;
}

.rooms {
  margin-left: 45px;
  margin-right: 140px;
}

.services {
  margin-left: auto;
  margin-right: 45px;
}

.box::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 12px;
}

.box::-webkit-scrollbar {
  width: 10px;
  height: 20px;
  background-color: transparent;
}

.box:hover {
  overflow-y: auto;
}

@media only screen and (min-width: 425px) and (max-width: 767px) {
  .about-me {
    width: 90%;
    text-align: center;
    margin: auto;
  }

  .about-section {
    flex-direction: column;
    justify-content: center;
    padding: 20px;
  }

  .google-maps-widget iframe {
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 20px;
  }

  .google-maps-widget {
    display: flex;
    flex-direction: column;
    width: auto;
  }

  .about-logo {
    justify-content: center;
    height: 70px;
    width: 120px;
    margin: auto;
    margin-bottom: 10px;
  }

  .about-text {
    text-align: center;
    font-size: 14px;
  }

  .contact {
    text-align: center;
    margin-top: 20px;
    margin-bottom: 10px;
  }

  .room1 {
    display: block;
    text-align: left;
    margin: auto;
    color: #166bda;
  }

  .rooms,
  .services {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
  }

  .box {
    overflow-y: auto;
    height: auto;
    max-height: 300px;
    width: 90%;
    margin: auto;
  }

  #bookBtn,
  #detailsBtn {
    width: 100%;
    margin-bottom: 10px;
  }
}

@media only screen and (min-width: 320px) and (max-width: 424px) {
  .about-me {
    width: 90%;
    text-align: center;
    margin: auto;
  }

  .about-section {
    flex-direction: column;
    justify-content: center;
    padding: 20px;
  }

  .google-maps-widget iframe {
    margin: auto;
    margin-bottom: 20px;
    width: 100%;
  }

  .about-logo {
    height: 70px;
    width: 100px;
    margin: auto;
    margin-bottom: 10px;
  }

  .about-text {
    text-align: center;
    font-size: 14px;
  }

  .contact {
    text-align: center;
    margin-top: 20px;
    margin-bottom: 10px;
  }

  .room1 {
    display: block;
    text-align: center;
    margin: auto;
    color: #166bda;
  }

  .rooms,
  .services {
    display: flex;
    flex-direction: column;
    text-align: center;
    margin: 0;
    margin-left: auto;
    margin-right: auto;
  }

  .box {
    overflow-y: auto;
    height: auto;
    max-height: 300px;
    width: 90%;
    margin: auto;
  }

  #bookBtn,
  #detailsBtn {
    width: 100%;
    margin-bottom: 10px;
  }

  .section-1 {
    width: 300px;
  }

  .contacts {
    margin-right: -21px;
    margin-left: 20px;
  }
}

@media (min-width: 1024px) {
  /* footer.css */
  .rooms {
    margin-left: 70px;
    margin-right: 70px;
  }

  .about-text {
    font-size: 14px;
  }
}

@media (min-width: 1440px) {
  /* footer.css */
  .rooms {
    margin-left: 45px;
    margin-right: 140px;
  }

  .about-text {
    font-size: 16px;
  }
}
