.success-wrapper {
  position: relative;
}

.success-header {
  background-image: url(../images/Naslovna.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  filter: blur(1.5px);
  z-index: 1;
}

.success-container {
  background-color: rgba(247, 247, 247, 0.95);
  color: #333;
  padding: 20px;
  border: 2px solid #007bff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
  text-align: center;
  max-width: 420px;
  height: 480px;
  margin: auto;
  margin-top: 60px;
  position: relative;
  z-index: 2;
}

.success-title {
  font-size: 28px;
  margin-bottom: 20px;
  color: #007bff;
}

.success-info {
  font-size: 18px;
  margin-bottom: 10px;
}

.guest-name,
.room-name,
.room-id,
.reservation-id {
  font-weight: bold;
}

.check-icon {
  font-size: 48px;
  color: #007bff;
  margin-bottom: 20px;
}

.buttons-print-close {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  gap: 10px;
  align-items: center;
}

.print-button,
.close-button {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}

.print-button:hover,
.close-button:hover {
  background-color: #0056b3;
}

@media (min-width: 1024px) and (max-width: 1439px) {
  .success-container {
    width: 50%;
    max-width: 650px;
    height: 100%;
    max-height: 590px;
    padding: 15px;
    font-size: 20px;
    margin-top: 4%;
  }

  .success-title {
    font-size: 34px !important;
    margin-bottom: 20px;
  }

  .success-info {
    font-size: 20px !important;
    margin-bottom: 10px !important;
  }

  .check-icon {
    font-size: 60px;
    margin-bottom: 5px;
  }

  .buttons-print-close {
    bottom: 10px;
  }

  .success {
    margin-bottom: 15%;
  }

  .print-button,
  .close-button {
    height: 40px;
    width: 80px;
    padding: 12px 24px;
    font-size: 20px;
    margin-top: 20px;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .success-container {
    max-width: 580px;
    height: 90%;
    padding: 20px;
    font-size: 20px;
    margin-top: 5%;
  }

  .success-info {
    margin-top: 40px;
    font-weight: 400;
    font-size: 24px !important;
    margin-bottom: 35px;
  }

  .success-title {
    font-size: 42px;
  }

  .check-icon {
    font-size: 100px;
    margin-bottom: 5px;
    margin-top: 15px;
  }

  .success {
    margin-bottom: 15%;
  }

  .buttons-print-close {
    margin-top: 5px;
    height: 80px;
    font-size: 40px;
  }

  .print-button {
    height: 40px;
    width: 100px;
    font-size: 34px;
  }

  .close-button {
    height: 40px;
    width: 100px;
    font-size: 34px;
  }
}

@media (min-width: 425px) and (max-width: 767px) {
  .success-container {
    margin-top: 10%;
    width: 70%;
    min-height: 100%;
    max-height: 720px;
  }

  .success-title {
    font-size: 26px;
  }

  .success {
    margin-bottom: 16%;
  }

  .success-info {
    font-size: 16px;
  }

  .check-icon {
    font-size: 40px;
  }
}

@media (min-width: 320px) and (max-width: 424px) {
  .success-container {
    margin-top: 10%;
    width: 75%;
    height: 75%;
    max-height: 600px;
  }

  .success-title {
    font-size: 23px;
  }

  .success {
    margin-bottom: 25%;
  }

  .success-info {
    font-size: 15px;
  }

  .check-icon {
    font-size: 34px;
    margin-bottom: 10px;
  }
}

@media (min-width: 1440px) {
  .success-container {
    margin-top: 5%;
    width: 53%;
    max-width: 610px !important;
    min-height: 550px;
  }

  .success-info {
    font-size: 20px;
  }
}
