:root {
  --yellow: linear-gradient(180deg, #f8d49a -146.42%, #fad79d -46.42%);
  --orange: #fca61f;
  --black: #242d49;
  --gray: #788097;
  --blue: linear-gradient(180deg, #678aff 0%, #8f84f3 100%);
  --pink: #919cff;
  --glass: rgba(255, 255, 255, 0.54);
  --boxShadow: 0 19px 60px rgb(0 0 0 / 8%);
  --smboxShadow: -79px 51px 60px rgba(0, 0, 0, 0.08);
  --activeItem: #3437d842;
}

.content {
  /* padding: 0.5rem 3.5rem; */
  color: var(--black);
  background:
    linear-gradient(
      106.37deg,
      #ffe1bc 29.63%,
      #52f3bd 51.55%,
      #3437d8 90.85%
    );
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Inter', sans-serif;
  overflow-x: auto;
}

.AppGlass {
  display: grid;
  height: 97%;
  width: 97%;
  background: var(--glass);
  border-radius: 2rem;
  gap: 16px;
  grid-template-columns: 15rem auto;
  overflow: hidden;
}

.AppGlass > * {
  border-right: 1px solid rgba(169, 200, 209, 0.589);
  padding-right: 16px;
}

@media screen and (max-width: 1024px) {
  .AppGlass {
    grid-template-columns: 30% auto;
  }
}

@media screen and (min-width: 1024px) {
  .AppGlass > * {
    padding-right: 0;
    width: 16vw;
    border: none;
  }

  .AppGlass {
    grid-template-columns: 16vw auto;
    gap: 0;
  }

  .content {
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
}

@media screen and  (max-width: 767px) {
  .AppGlass {
    grid-template-columns: 1fr;
  }
}

@media screen and (min-width: 374px) and (max-width: 375px) {
  .AppGlass {
    grid-template-columns: 1fr;
    grid-template-rows: auto auto; /* Adjust rows if necessary */
  }

  .AppGlass > * {
    border-right: none;
    padding-right: 0;
  }

  .content {
    height: auto;
    padding: 1rem;
  }

  .bars {
    width: 35px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .AppGlass {
    gap: 16px;
  }
}
