.container {
  column-gap: 20px;
  display: flex;
  flex-direction: column;
  color: white;
}

/* Style labels */
.startDate {
  display: block;
  margin-bottom: 10px;
  margin-right: 10px;
}

.endDate {
  display: block;
  margin-bottom: 10px;
  margin-right: 10px;
}

.capacity {
  display: flex;
  flex-direction: column;
}

#capacity {
  border-color: white;
  font-style: oblique;
  width: 30%;
  height: 35px;
  padding-left: 15px;
  margin-top: 5px;
  margin-bottom: -10px;
  margin-left: 5px;
  margin-right: 15px;
  box-sizing: border-box;
  background-color: transparent;
  border-radius: 10px;
  color: white;
  font-size: 15px;
}

#startDate {
  font-style: oblique;
  width: 100%;
  padding: 8px;
  margin-top: 5px;
  margin-bottom: 10px;
  box-sizing: border-box;
  background-color: transparent;
  border-color: white;
  border-radius: 10px;
  color: white;
}

#startDate:hover {
  border-color: rgb(224, 224, 224);
}

#endDate {
  font-style: oblique;
  width: 100%;
  padding: 8px;
  margin-top: 5px;
  margin-bottom: 10px;
  box-sizing: border-box;
  background-color: transparent;
  border-color: white;
  border-radius: 10px;
  color: white;
}

#endDate:hover {
  border-color: rgb(224, 224, 224);
}

/* Style the button */
button {
  cursor: pointer;
}

/* Style the available rooms section */
h3 {
  margin-top: 20px;
}

ul {
  list-style: none;
  padding: 0;
}

li {
  margin-bottom: 5px;
}

::-webkit-calendar-picker-indicator {
  color: white;
  filter: invert(100%);
}

.plus {
  width: 15%;
  margin-top: 29px;
  margin-bottom: 1px;
  margin-left: -35px;
  margin-right: -5px;
  background-color: transparent;
  color: white;
  border: none;
  font-size: 25px;
  padding-right: 20px !important;
}

.plus:hover {
  border-color: rgb(224, 224, 224);
}

.minus {
  width: 15%;
  margin-top: 29px;
  margin-bottom: 1px;
  background-color: transparent;
  color: white;
  border: none;
  font-size: 27px;
  padding-left: 0;
}

.minus:hover {
  border-color: rgb(152, 25, 25);
}

.separator {
  width: 15%;
  margin-top: 30px;
  margin-bottom: 1px;
  margin-right: 7px;
  margin-left: 0;
  background-color: transparent;
  color: white;
  border: none;
  font-size: 30px;
}

.button-container-ca {
  margin-left: 27px;
  display: flex;
  flex-direction: row;
  column-gap: 2px;
  margin-top: -25px;
  border-radius: 10px;
}

.available-rooms-container {
  margin-top: 15px;
  width: 1300px;
  display: flex;
  justify-content: center;
  gap: 10px;
}

.available-rooms-container > div {
  width: 100%;
  margin-bottom: 10px;
  margin-top: 5px;
}

.dates {
  margin-top: 15px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.main-image-container {
  margin-top: 5px;
  width: 75%;
}

.reserve-button {
  position: absolute;
  display: block;
  margin-top: 15%;
  padding: 8px 16px;
  background-color: #3e3cb4;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.availability-button {
  margin-top: 18px;
  margin-left: -130px;
  border: none;
  padding: 2px 5px;
  width: 125px;
  height: 42px;
  color: rgb(65, 65, 65);
  background-color: rgba(215, 230, 244, 0.8);
  font-size: 12px;
  font-weight: bold;
  border-radius: 16px;
  transition: background-color 0.3s, color 0.3s, border-color 0.3s;
}

.availability-button:hover {
  background-color: rgb(154, 159, 211);
  transform: translateY(-2px);
  color: rgb(227, 227, 227);
}

.modal-content {
  margin-top: 45px;
  width: 68%;
  height: 78%;
  background-color: #e0e0e0;
  border-radius: 25px;
}

.navigation-icon {
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
  cursor: pointer;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background: #adadad;
  display: flex;
  justify-content: center;
  align-items: center;
}

.navigation-icon-next {
  position: absolute;
  top: 50%;
  right: -105px;
  transform: translateY(-50%);
  cursor: pointer;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background: #adadad;
  display: flex;
  justify-content: center;
  align-items: center;
}

.thumbnail-container {
  margin-left: -4px;
  width: 99%;
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .container {
    display: flex;
    flex-direction: column;
    column-gap: 10px;
  }

  .startDate,
  .endDate,
  .capacity {
    margin-bottom: 5px;
  }

  .button-container-ca {
    position: static;
    margin-top: 1px;
    margin-left: 25px;
  }

  .plus,
  .minus,
  .separator {
    width: 30%;
    font-size: 20px;
    margin-top: 12px;
  }

  .available-rooms-container {
    width: auto;
    flex-wrap: wrap;
  }

  .available-rooms-container > div {
    width: 100%;
  }

  .reserve-button {
    margin-top: 10%;
  }

  .main-image-container {
    display: flex;
    margin-left: 15%;
    margin-right: 15%;
    width: 70%;
    margin-bottom: 5px;
  }

  .navigation-icon {
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translateY(-50%);
    cursor: pointer;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background: #ccc;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .navigation-icon-next {
    position: absolute;
    top: 50%;
    right: 5px;
    transform: translateY(-50%);
    cursor: pointer;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background: #ccc;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media only screen and (min-width: 425px) and (max-width: 767px) {
  .dates {
    margin-top: 30px;
    width: auto;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
  }

  .startDate,
  .endDate,
  .capacity {
    margin-bottom: 10px;
    margin-right: 0;
  }

  #capacity {
    width: 100%;
    margin-top: 10px;
  }

  .plus,
  .minus,
  .separator {
    width: 100%;
    margin-top: 10px;
    margin-left: 0;
    margin-right: 0;
    text-align: center;
  }

  .plus,
  .minus {
    margin-bottom: 5px;
  }
}
