/* .overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.08);
  display: flex;
  justify-content: center;
  align-items: center;
} */

.reservation-logo {
  position: fixed;
  top: 0;
  left: 0;
}

.reservation-form-container {
  background-color: #ebebeb;
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.85);
  height: 500px;
  width: 650px;
}

.form-header {
  font-size: 1.5em;
  margin-bottom: 25px;
  text-align: center;
}

.form-label {
  display: flex;
  flex-direction: row;
  color: black;
}

.label-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 115px;
}

.inputs-row {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  gap: 60px;
}

.start_date,
.end_date {
  border-radius: 10px;
  border: 1px solid gray;
  padding: 10px;
  margin-bottom: 1px;
}

.start_date::-webkit-calendar-picker-indicator {
  filter: invert(0);
  border: none;
  border-radius: 4px;
  font-size: 16px;
}

.end_date::-webkit-calendar-picker-indicator {
  /* filter: invert(1) sepia(1) saturate(90) hue-rotate(290deg);
  border: 2px solid #13a1c9;
  border-radius: 4px; */
  filter: invert(0);
  border: none;
  border-radius: 4px;
  font-size: 16px;
}

.form-actions {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
}

.date-inputs {
  margin-left: auto;
  margin-right: auto;
  display: flex;
  align-items: center;
  gap: 10px;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 16px;
  margin-bottom: 5px;
  margin-top: 20px;
}

.button-container-rf {
  text-align: center;
}

.button-reservation {
  background-color: black;
  color: white;
  margin-bottom: -15px;
  padding: 8px 16px;
  border: none;
  cursor: pointer;
  font-style: italic;
  border-radius: 7px;
  font-size: 13px;
  box-shadow: 20px 20px 20px rgba(239, 238, 238, 0.1);
}

.button-reservation:hover {
  background-color: rgb(163, 163, 163);
  color: rgb(255, 255, 255);
  transform: translateY(-1px);
}

.stripe_button {
  display: flex;
  flex-direction: row;
  gap: 70px;
  justify-content: center;
  margin-top: 10px;
}

.btn.btn-primary {
  background-color: rgb(20, 82, 130);
  color: white;
  margin-bottom: -15px;
  padding: 8px 16px;
  border: none;
  cursor: pointer;
  font-style: italic;
  border-radius: 7px;
  font-size: 13px;
  box-shadow: 20px 20px 20px rgba(239, 238, 238, 0.1);
}

.delete-button {
  display: flex;
  flex-direction: row;
  gap: 3px;
  background-color: rgb(130, 20, 44);
  color: white;
  margin-bottom: -9px;
  padding: 7px 16px;
  border: none;
  cursor: pointer;
  font-style: italic;
  border-radius: 7px;
  font-size: 13px;
  box-shadow: 20px 20px 20px rgba(239, 238, 238, 0.1);
}

.image-frame {
  position: relative;
  align-items: center;
  margin-right: auto;
  margin-left: auto;
  margin-top: 20px;
  width: 520px;
  max-width: 520px;
  height: 290px;
  max-height: 290px;
  border-radius: 10px;
  margin-bottom: 5px;
}

.room-image {
  border-radius: 10px;
  width: 520px;
  height: 290px;
}

.reservation-card1 {
  margin-left: 20px;
  border: 1px solid #ccc;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 290px;
  background-color: #ebebeb;
  transition: transform 0.3s ease-in-out;
  margin-right: 20px;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 20px;
}

.reservation-card1:hover {
  transform: translateY(-5px);
}

.reservation-card1 h2 {
  color: #333;
  font-size: 20px;
  margin-bottom: 8px;
  font-weight: bold;
}

.reservation-card1 p {
  margin: 4px 0;
  color: #666;
  font-size: 14px;
  line-height: 1.2;
}

.back-button-container {
  position: absolute;
}

.back-button-container a {
  position: absolute;
  text-decoration: none;
}

.home-button {
  background-color: #3b5f83;
  position: absolute;
  border: none;
  color: white;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 18px;
  border-radius: 14px;
  cursor: pointer;
  z-index: 1000;
  transition: background-color 0.3s ease;
}

.back-button-container button:hover {
  background-color: #2a4661;
}

.info_text {
  display: flex;
  align-items: center;
  margin-top: 14px;
  color: red;
}

.info_text p {
  color: red;
  font-size: 10px;
  font-weight: bold;
  margin-left: 10px;
}

@media only screen and (min-width: 320px) and (max-width: 424px) {
  .book-page {
    height: auto;
  }

  .section-1-reservation {
    background-color: whitesmoke;
    opacity: 0.8;
    display: flex;
    max-height: 60px;
    justify-content: center;
    margin-bottom: 10px !important;
  }

  .logoA {
    width: 45%;
    max-width: 100px;
    height: auto;
  }

  .overlay {
    margin-top: 1%;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100% !important;
    max-height: 948px;
    min-height: 350px;
    z-index: 9999;
  }

  .reservation-form-container {
    width: 80%;
    max-width: 350px !important;
    height: 55%;
    margin-top: 4%;
    margin-bottom: 4%;
  }

  .back-button-container {
    display: flex;
    justify-content: center;
    position: absolute;
    left: 1px;
    margin: 13px;
    width: 5%;
  }

  .back-button-container button {
    width: 45px;
    height: 32px;
  }

  .form-header {
    margin-top: 10px;
    width: 100%;
    height: 10%;
    font-size: 16px;
    margin-bottom: -5px;
  }

  .image-frame {
    width: 85%;
    min-height: 140px;
    max-height: 170px;
  }

  .room-image {
    width: 100%;
    height: 100%;
  }

  .date-inputs {
    width: 100%;
  }

  .inputs-row {
    width: 100%;
    gap: 5%;
    max-width: 310px;
  }

  .label-row {
    font-size: 14px;
    display: flex;
    align-items: flex-start;
    width: 100%;
    max-width: 310px;
    justify-content: space-around;
    flex-direction: row;

    /* border: 2px solid red; */
    gap: 5% !important;
  }

  .start_date {
    max-height: 8px;
    width: 50%;
  }

  .end_date {
    max-height: 8px;
    width: 50%;
  }

  .reservation-card1 {
    width: 70% !important;
    max-width: 240px;
    margin-bottom: 20px;
    font-size: 10px;
  }

  .button-container-rf {
    margin-top: -10px;
  }

  .images {
    width: 100% !important;
    height: 60% !important;
  }

  .card-title {
    margin-top: 12px;
    font-size: 14px;
  }

  .button-reservation {
    font-size: 15px;
    min-width: 100px;
    min-height: 34px;
  }

  .stripe_button {
    gap: 55px;
  }

  .info_text {
    font-size: 16px;
  }
}

@media only screen and (min-width: 425px) and (max-width: 767px) {
  .book-page {
    height: auto;
  }

  .section-1-reservation {
    background-color: whitesmoke;
    opacity: 0.8;
    display: flex;
    max-height: 96px;
    justify-content: center;
  }

  .logoA {
    width: 160px;
  }

  .back-button-container {
    display: flex;
    justify-content: center;
    position: absolute;
    left: 1px;
    margin: 28px;
    width: 1%;
  }

  .home-button {
    width: 55px;
    height: 40px;
  }

  .overlay {
    margin-top: 1%;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 700px;
  }

  .reservation-form-container {
    width: 85%;
    max-width: 400px !important;
    height: 53%;
    margin-top: 1%;
    margin-bottom: 5%;
  }

  .reservation-card1 {
    margin-bottom: 30% !important;
  }

  .image-frame {
    min-height: 100px !important;
    max-height: 200px !important;
    width: 100%;
    max-width: 300px;
  }

  .room-image {
    width: 100%;
    height: 100%;
  }

  .date-inputs {
    min-width: 76%;
  }

  .inputs-row {
    min-width: 100%;
    gap: 5% !important;
  }

  .form-label {
    width: 100px;
    margin-left: 5%;
  }

  .label-row {
    width: 100%;
    gap: 5% !important;
  }

  .start-label {
    width: 51%;
  }

  .end-label {
    width: 49%;
  }

  .end_date {
    width: 50%;
  }

  .start_date {
    width: 50%;
  }

  .button-reservation {
    font-size: 18px;
    min-width: 120px;
    min-height: 36px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .book-page {
    height: auto;
  }

  .section-1-reservation {
    background-color: whitesmoke;
    opacity: 0.8;
    display: flex;
    height: 79px;
    justify-content: center;
  }

  .logoA {
    width: 132px;
  }

  .back-button-container {
    display: flex;
    justify-content: center;
    position: absolute;
    left: 1px;
    margin: 25px;
    margin-top: 14px;
    width: 1%;
  }

  .back-button-container button {
    width: 75px;
    height: 45px;
  }

  .overlay {
    margin-top: 1%;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 700px;
  }

  .reservation-form-container {
    width: 85%;
    max-width: 460px !important;
    height: 430px;
    margin-top: 1%;
    margin-bottom: 5%;
  }

  .reservation-card1 {
    margin-bottom: 5% !important;
    max-height: 466px;
  }

  .image-frame {
    width: 95%;
    max-width: 370px;
    height: 190px;
  }

  .room-image {
    width: 100%;
    height: 100%;
  }

  .images {
    width: 100% !important;
    height: 40% !important;
  }

  /* input[type="date" i] {
    height: 8px;
    margin-bottom: -10px;
  } */
  .button-reservation {
    height: 40px;
    width: 130px;
    font-size: 16px;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1439px) {
  .book-page {
    height: auto;
  }

  .section-1-reservation {
    background-color: whitesmoke;
    opacity: 0.8;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    height: 84px;
  }

  .logoA {
    margin-left: auto;
    margin-right: auto;
    width: 130px;
  }

  .back-button-container {
    left: 1px;
    margin: 32px;
    width: 1px;
    margin-top: -15px;
  }

  .home-button {
    width: 57px;
    height: 41px;
  }

  .overlay {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  .reservation-form-container {
    margin-top: 1%;
    height: 449px;
    width: 600px;
    margin-bottom: 25px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }

  .reservation-card1 {
    margin-top: 0;
    margin-bottom: 0;
    max-height: 440px;
  }

  .image-frame {
    height: 210px;
    width: 370px;
  }

  .room-image {
    height: 210px;
    width: 370px;
  }

  /* input[type="date" i] {
    height: 8px;
    margin-bottom: 2px;
    margin-top: -6px;
  } */
  .button-reservation {
    font-size: 18px;
    min-width: 125px;
    min-height: 41px;
    margin-top: -2px;
  }

  .form-header {
    margin-top: 0;
    margin-bottom: -4px;
  }
}

@media only screen and (min-width: 1440px) {
  .section-1-reservation {
    background-color: whitesmoke;
    opacity: 0.8;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    height: 104px;
  }

  .logoA {
    margin-left: auto;
    margin-right: auto;
    width: 159px;
  }

  .back-button-container {
    left: 1px;
    margin: 32px;
    width: 1px;
    margin-top: -32px;
  }

  .home-button {
    margin-right: -500px;
    width: 90px;
    height: 52px;
  }

  .overlay {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .reservation-form-container {
    margin-top: 1%;
    height: 489px;
    width: 600px;
    margin-bottom: 25px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }

  .image-frame {
    width: 490px;
    max-width: 500px;
    height: 270px;
    max-height: 280px;
  }

  .room-image {
    width: 100%;
    max-width: 100%;
    height: 100%;
  }

  /* input[type="date" i] {
    height: 14px;
    width: 130px;
    margin-bottom: 2px;
  } */
  .reservation-card1 {
    margin-top: 2%;
    width: 20%;
    margin-left: 40px;
    font-size: 18px;
    max-height: 427px;
  }

  .button-reservation {
    margin-top: 7px;
    font-size: 20px;
    min-width: 140px;
    min-height: 40px;
  }

  .form-header {
    margin-bottom: -4px;
  }
}
