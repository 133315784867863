.section {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 105vh;
  width: 100%;
  background: url(../images/bg-signin.jpeg) no-repeat;
  background-position: center;
  background-size: cover;
  font-family:
    "Trebuchet MS",
    "Lucida Sans Unicode",
    "Lucida Grande",
    "Lucida Sans",
    Arial,
    sans-serif;
}

.form-container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 20px;
}

.form-box-signin,
.form-box-additional {
  position: relative;
  width: 400px;
  height: 700px !important;
  background: transparent;
  border: none;
  border-radius: 20px;
  backdrop-filter: blur(15px) brightness(80%);
  display: flex;
  justify-content: center;
  align-items: center;
}

.form-box-signup {
  position: relative;
  width: 400px;
  height: 700px !important;
  background: transparent;
  border: none;
  border-radius: 20px;
  backdrop-filter: blur(15px) brightness(80%);
  display: flex;
  justify-content: center;
  align-items: center;
}

.form-box-confirm {
  position: relative;
  width: 800px;
  height: 600px !important;
  background: transparent;
  border: none;
  border-radius: 20px;
  backdrop-filter: blur(15px) brightness(80%);
  display: flex;
  justify-content: center;
  align-items: center;
}

.form-box-forgot {
  position: relative;
  width: 400px;
  height: 450px !important;
  background: transparent;
  border: none;
  border-radius: 20px;
  backdrop-filter: blur(15px) brightness(80%);
  display: flex;
  justify-content: center;
  align-items: center;
}

.signup-text {
  font-size: 2em;
  color: #fff;
  text-align: center;
}

.text {
  font-size: 2em;
  color: #fff;
  text-align: center;
}

.text-sign-in {
  font-size: 2em;
  color: #fff;
  text-align: center;
}

.text-policy {
  font-size: 1.2em;
  color: #fff;
  text-align: center;
}

.email-text {
  color: aqua;
  text-align: center;
}

.email-container {
  flex: 1;
  display: flex;
  justify-content: center;
}

.home-container {
  display: flex;
  justify-content: flex-end;
  margin-top: 90px;
}

.home-link {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: inherit;
}

.email-icon,
.home-icon {
  color: aqua;
  font-size: 50px;
}

.back-home {
  color: white;
  margin-right: 8px;
  font-weight: bold;
}

.inputbox {
  position: relative;
  margin: 30px 0;
  width: 310px;
  border-bottom: 2px solid #fff;
}

.inputbox label {
  position: absolute;
  top: 50%;
  left: 5px;
  transform: translateY(-50%);
  color: #fff;
  font-size: 1em;
  pointer-events: none;
  transition: 0.5s;
}

.input:focus ~ label,
.input:valid ~ label {
  top: -5px;
}

.inputbox input {
  width: 100%;
  height: 50px;
  background: transparent;
  border: none;
  outline: none;
  font-size: 1em;
  padding: 0 35px 0 5px;
  color: #fff;
}

.inputbox .icon {
  position: absolute;
  right: 8px;
  color: #fff;
  font-size: 1.2em;
  top: 20px;
}

.password-wrapper {
  position: relative;
  display: flex;
  align-items: center;
}

.password-wrapper .password-toggle-icon {
  position: absolute;
  right: 10px;
  cursor: pointer;
}

.react-tel-input input[type="tel"] {
  border: none;
  outline: none;
  color: #fff;
  background-color: transparent;
  padding: 15px;
  padding-left: 60px;
  width: 100%;
  font-size: 16px;
  position: relative;
  margin: 30px 0;
  border-bottom: 2px solid #fff;
}

.react-tel-input .flag-dropdown {
  background-color: transparent;
}

.react-tel-input .country-list {
  border: 1px solid white;
  background-color: transparent;
}

.react-tel-input .country-list .country.active {
  background-color: #f0f0f0;
}

.react-tel-input .country-list .country-name {
  font-size: 14px;
}

.react-tel-input .country-list .dial-code {
  font-size: 12px;
}

.scroll-box {
  border: 2px solid white;
  width: 340px;
  height: 340px !important;
  overflow-y: auto;
}

.scroll-box p {
  margin: 10px;
  color: white;
}

.forget {
  margin: -10px 0 17px;
  font-size: 0.9em;
  color: #fff;
  display: flex;
  justify-content: space-between;
}

.forget label input {
  margin-right: 3px;
}

.forget a {
  color: #fff;
  text-decoration: none;
}

.register p a {
  text-decoration: none;
  color: #fff;
  font-weight: 600;
}

.forget a:hover {
  text-decoration: underline;
}

.signup-button {
  width: 100%;
  height: 40px;
  border-radius: 40px;
  background-color: #fff;
  border: none;
  outline: none;
  cursor: pointer;
  font-size: 1em;
  font-weight: 600;
  color: dimgrey;
}

.register {
  font-size: 0.9em;
  color: #fff;
  text-align: center;
  margin: 25px 0 10px;
}

.register p a:hover {
  text-decoration: underline;
}

.form-box-reset {
  padding: 49px;
  backdrop-filter: blur(15px) brightness(80%);
  border-radius: 13px;
  display: flex;
}

@media screen and (min-width: 320px) and (max-width: 425px) {
  .form-box-signin {
    width: 88%;
    height: 75% !important;
    min-height: 410px;
    border-radius: 12px;
    margin-top: -20px;
  }

  .form-value {
    margin-top: -40px;
    width: 90%;
    max-height: 560px;
  }

  .inputbox {
    width: 100%;
    margin-top: 25px;
    height: 38px;
    margin-bottom: 15px;
  }

  .inputbox input {
    width: 100%;
  }

  .inputbox label {
    width: 95%;
    font-size: 0.85em;
  }

  /* signup */

  .form-container {
    width: 95%;
    height: 80% !important;
    max-height: 568px;
  }

  .form-box-signup {
    margin-top: -12%;
    width: 90%;
    height: 70%;
    max-height: 500px;
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .signup-text {
    font-size: 1.45em;
    margin-top: 40px;
    margin-bottom: 20px !important;
  }

  .text {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .text-sign-in {
    font-size: 1.7rem;
    margin-bottom: 50px;
  }

  .input.input {
    width: 95%;
  }

  .signup-button {
    margin-left: 23%;
    margin-right: 23%;
    width: 54%;
    min-height: 8%;
    max-height: 30px;
    margin-top: -10px;
  }

  /* T&C Privacy Policy */
  .form-box-additional {
    margin-top: -15px;
    width: 95%;
    max-height: 560px;
  }

  .scroll-box {
    width: 100%;
  }

  .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.css-9nodk5-MuiSvgIcon-root {
    margin: 0;
    position: absolute;
    right: 15px;
  }

  .text-policy {
    font-size: 1.1rem;
  }
}

@media screen and (min-width: 425px) and (max-width: 767px) {
  .form-box-signin {
    margin-top: 30px;
    width: 82%;
    max-width: 480px;
    height: 75% !important;
    min-height: 540px;
    border-radius: 12px;
  }

  .text {
    top: 0;
  }

  .text-sign-in {
    font-size: 1.7rem;
    margin-top: -30px;
    margin-bottom: 60px;
  }

  .form-value {
    width: 85%;
  }

  .inputbox {
    width: 100%;
  }

  .inputbox input {
    width: 100%;
  }

  .inputbox label {
    width: 95%;
  }

  /* T&C Privacy Policy */
  .section {
    display: flex;
    align-items: center;
  }

  .form-box-signup {
    margin-top: 30px;
  }

  .form-box-additional {
    margin-top: 30px;
  }
}

@media screen and (max-width: 1024px) {
  .form-box-confirm {
    width: 380px;
    height: 700px !important;
  }
}
