.icons_room {
  margin-top: 2%;
  display: flex;
  flex-direction: row;
  gap: 160px;
  font-size: 15px;
}

.bed_icons {
  display: flex;
  flex-direction: row;
  gap: 20px;
  font-size: 15px;
}

.description {
  font-size: 15px;
  text-align: justify;
  margin-right: 10px;
}

.private_kitchen {
  display: flex;
  flex-direction: row;
  gap: 160px;
  font-size: 15px;
}

.private_bathroom {
  display: flex;
  flex-direction: row;
  gap: 100px;
  font-size: 15px;
}

.private_bathroom_2 {
  padding-left: 140px;
}

.private_bathroom_3 {
  padding-left: 20px;
}

.view {
  display: flex;
  flex-direction: row;
  gap: 100px;
  font-size: 15px;
}

.view_2 {
  padding-left: 120px;
}

.view_3 {
  padding-left: 50px;
}

.room_facilities {
  display: flex;
  flex-direction: row;
  gap: 100px;
  font-size: 15px;
}

.room_facilities_1 {
  /* margin-right: 80px; */
  width: 180px;
}

.room_facilities_2 {
  padding-left: 10px;
}

.room_facilities_3 {
  padding-left: 40px;
}

.expand-toggle {
  display: flex;
  width: 23%;
  justify-content: space-between;
}

.expand-toggle-one {
  margin-top: 2%;
  display: flex;
  width: 23%;
  justify-content: space-between;
}

.expand-toggle-two {
  margin-top: 2%;
  display: flex;
  width: 23%;
  justify-content: space-between;
}

.expand-toggle-three {
  margin-top: 2%;
  display: flex;
  width: 23%;
  justify-content: space-between;
}

@media (min-width: 768px) and (max-width: 1023px) {
  .roomamenities_container {
    width: 70%;
    margin-right: 1% !important;
  }

  .expand-toggle {
    width: 55%;
  }

  .expand-toggle-one {
    margin-top: 3%;
    width: 55%;
  }

  .expand-toggle-two {
    margin-top: 3%;
    width: 55%;
  }

  .expand-toggle-three {
    margin-top: 3%;
    width: 55%;
  }
}

@media (min-width: 425px) and (max-width: 767px) {
  .roomamenities_container {
    /* width: 80%; */
    margin-left: 10%;
    margin-right: 5%;
  }

  .icons_room {
    gap: 8%;
  }

  .description {
    text-align: justify;
    margin-right: 5%;
  }

  .private_kitchen {
    gap: 8%;
  }

  .private_kitchen_2 {
    padding-left: 0.5%;
    flex-wrap: wrap;
    width: 35%;
  }

  .private_kitchen_3 {
    padding-left: 0.5%;
  }

  .private_bathroom {
    gap: 4%;
  }

  .private_bathroom_2 {
    padding-left: 19%;
    flex-wrap: wrap;
    width: 25%;
  }

  .private_bathroom_3 {
    padding-left: 2%;
  }

  .view {
    gap: 4%;
  }

  .view_2 {
    padding-left: 14%;
  }

  .view_3 {
    padding-left: 0%;
  }

  .room_facilities {
    gap: 4%;
  }

  .room_facilities_1 {
    padding-left: 0%;
    flex-wrap: wrap;
    width: 37%;
  }

  .room_facilities_2 {
    padding-left: 1%;
  }

  .room_facilities_3 {
    padding-left: 0%;
  }

  .expand-toggle {
    width: 55%;
  }

  .expand-toggle-one {
    width: 55%;
  }

  .expand-toggle-two {
    width: 55%;
  }

  .expand-toggle-three {
    width: 55%;
  }
}

@media (min-width: 320px) and (max-width: 425px) {
  .roomamenities_container {
    /* width: 80%; */
    margin-left: 5%;
    margin-right: 5%;
  }

  .icons_room {
    gap: 8%;
  }

  .description {
    text-align: justify;
    margin-right: 5%;
  }

  .private_kitchen {
    gap: 8%;
  }

  .private_kitchen_2 {
    padding-left: 0%;
    flex-wrap: wrap;
    width: 100%;
  }

  .private_kitchen_3 {
    padding-left: 0.5%;
  }

  .private_bathroom {
    gap: 4%;
  }

  .private_bathroom_2 {
    padding-left: 0%;
  }

  .private_bathroom_3 {
    padding-left: 0%;
  }

  .view {
    gap: 4%;
  }

  .view_2 {
    padding-left: 0%;
  }

  .view_3 {
    padding-left: 0%;
  }

  .room_facilities {
    gap: 4%;
  }

  .room_facilities_1 {
    padding-left: 0%;
    flex-wrap: wrap;
    width: 100%;
  }

  .room_facilities_2 {
    width: 100%;
    padding-left: 0%;
  }

  .room_facilities_3 {
    width: 100%;
    padding-left: 0%;
  }

  .expand-toggle {
    width: 75%;
  }

  .expand-toggle-one {
    width: 75%;
  }

  .expand-toggle-two {
    width: 75%;
  }

  .expand-toggle-three {
    width: 75%;
  }
}
