.menu-toggle {
  display: flex;
  align-items: center;
  cursor: pointer;
  height: 45px;
  width: 85px;
  color: #949292;
  font-size: 18px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 5px;
  transition: color 0.3s ease;
  border-radius: 30px;
  background-color: #fff;
  border: 1px solid #b6b2b2;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.menu-toggle:hover {
  color: #555;
  box-shadow: 0 18px 24px rgba(0, 0, 0, 0.1);
}

.avatar {
  margin-left: 0;
  margin-right: auto;
  margin-top: -14px;
  margin-bottom: -14.8px;
}

.hamburger {
  margin-left: auto;
  margin-right: 7px;
  color: #949292;
  font-size: 24px;
  transition: color 0.3s ease;
}

.hamburger:hover {
  color: #555;
}

.dropdown-menu {
  position: fixed;
  top: 80px;
  right: 26px;
  width: 180px;
  background-color: #fff;
  list-style: none;
  padding: 20px;
  margin: 0;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s ease;
  z-index: 1000;
}

.dropdown-menu li {
  padding: 10px 0;
  border-bottom: 1px solid #eee;
}

.dropdown-menu li:last-child {
  border-bottom: none;
}

.dropdown-menu a {
  color: #333;
  text-decoration: none;
  transition: color 0.3s ease;
}

.dropdown-menu a:hover {
  color: #007bff;
}

.dropdown-menu.show {
  opacity: 1;
  pointer-events: all;
}

@media only screen and (min-width: 320px) and (max-width: 424px) {
  .avatar {
    margin-left: 5%;
    margin-top: -20% !important;
    margin-bottom: -15%;
  }

  .avatar-image-navbar {
    aspect-ratio: 1/1;
    width: 31vw;
    max-width: 105px;
    height: auto;
  }

  .navigation-buttons-nav {
    margin-bottom: -80%;
  }

  .button-nav {
    margin-left: 8%;
    margin-right: 12%;
    width: 70%;
    height: 28%;
    max-height: 20px;
    font-size: 17.5px;
  }

  .navbar-buttons {
    margin-top: -25%;
    margin-bottom: 40%;
  }

  .button-logout-nav {
    margin-left: 8%;
    margin-right: 12%;
    width: 70%;
    max-height: 20px;
    margin-bottom: 57%;
    font-size: 17.5px;
  }
}

@media only screen and (min-width: 425px) and (max-width: 767px) {
  .avatar {
    margin-left: 8.2%;
    margin-top: -18% !important;
    margin-bottom: 0.5%;
    min-width: 120px;
    min-height: 120px;
  }

  .avatar-image-navbar {
    aspect-ratio: 1/1;
    width: 28vw;
    max-width: 130px;
    height: auto;
  }

  .navigation-buttons-nav {
    margin-bottom: -80%;
  }

  .button-nav {
    margin-left: 1%;
    margin-right: 12%;
    width: 86%;
    height: 32%;
  }

  .button-logout-nav {
    margin-left: 1%;
    margin-right: 12%;
    width: 86%;
    height: 32%;
    margin-bottom: 45%;
  }

  .navbar-nav {
    width: 65% !important;
  }

  .navbar-buttons {
    margin-top: 5%;
    margin-bottom: 90%;
  }

  .phone-hamburger-nav {
    margin-top: 220px !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .avatar {
    margin-left: 6%;
    margin-top: 10% !important;
    margin-bottom: 20%;
  }

  .avatar-image-navbar {
    aspect-ratio: 1/1;
    width: 20vw;
    max-width: 172px;
    height: auto;
  }

  .navigation-buttons-nav {
    margin-bottom: -80%;
  }

  .button-nav {
    margin-left: -18%;
    margin-right: 12%;
    width: 120%;
    max-width: 500px;
    min-height: 70%;
  }

  .button-logout-nav {
    margin-left: -18%;
    margin-right: 12%;
    width: 120%;
    min-height: 70%;
  }

  .navbar-nav {
    width: 65% !important;
  }

  .phone-hamburger-nav {
    margin-top: 390px !important;
  }

  .navbar-buttons {
    margin-top: 25%;
    margin-bottom: -75%;
  }
}
