@media (min-width: 320px) and (max-width: 424px) {
  .contacts {
    width: 50px;
  }

  .header {
    display: flex;
    flex-direction: column !important;
    height: 750px !important;
  }

  .checkavaliliability {
    display: flex;
    flex-direction: column !important;
  }

  .dates {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .availability-button {
    margin-left: 50px;
    margin-right: 50px;
    width: 50%;
  }

  .startDate {
    font-size: 14px;
    margin-left: 50px;
    margin-right: 50px;
    width: 50%;
    margin-bottom: 1px;
  }

  .endDate {
    font-size: 14px;
    margin-right: 50px;
    margin-left: 50px;
    width: 50%;
    margin-bottom: 1px;
  }

  .capacity {
    text-align: left;
    position: relative;
    width: 50%;
    margin-top: -2px;
    margin-left: -2px;
    margin-bottom: 5px;
  }

  #capacity {
    position: relative;
    width: 100%;
    margin-left: 1px;
  }

  .header-title {
    margin-top: -430px;
    position: absolute;
    font-size: 42px;
    margin-bottom: 10px;
    width: 86.9%;
  }

  .weather {
    height: 300px;
    position: absolute;
    margin-top: 920px;
    margin-left: 2px;
  }

  .current_weather {
    margin-left: 20%;
    margin-right: 20%;
    width: 60% !important;
  }

  .current_temp_hover {
    margin-top: -15px;
    margin-left: -61px;
    margin-right: 5px;
  }

  .blinking-arrow {
    color: red !important;
  }

  .current_temp {
    color: rgb(62, 102, 150) !important;
  }

  .signature {
    font-size: 20px;
    position: static;
    margin-top: 20px;
    margin-right: 5px;
    width: 210px;
  }

  .available-rooms-container {
    display: flex;
    flex-direction: column;
    width: 320px;
    max-width: 320px;
    margin: 0 auto;
  }

  .footer-container {
    position: fixed;
    bottom: 0;
    width: 100%;
  }

  .footer-content {
    margin-top: -5px;
    margin-left: -45px;
    position: static;
  }

  .review-section {
    height: 940px !important;
    display: flex;
    flex-direction: column;
  }

  .review-wrapper {
    display: flex !important;
    flex-direction: column !important;
  }

  .review-card:nth-child(1) {
    width: 80%;
    height: 230px;
  }

  .review-card:nth-child(3) {
    width: 80%;
    height: 230px;
  }

  .review-wrapper .review-card:nth-child(2) {
    width: 90%;
    max-height: 230px;
  }
}

@media (min-width: 425px) and (max-width: 767px) {
  /* body {
    width: var(--root-width);
    height: var(--root-height);
  } */

  .section-1 {
    position: fixed;
  }

  .menu-toggle {
    position: relative;
    left: 0;
    margin-right: 50px;
    width: 40px;
  }

  .avatar {
    position: relative;
    width: 120px;
    right: 5px;
  }

  .dates {
    display: flex;
    flex-direction: column;
    align-content: center;
  }

  .availability-button {
    position: relative;
    margin-left: 150px;
    margin-right: 150px;
    margin-top: 26px;
    width: 50%;
  }

  .startDate,
  .endDate {
    margin-left: 100px;
    margin-right: 100px;
    width: 50%;
    position: relative;
    margin-bottom: 10px;
  }

  .capacity {
    text-align: left;
    position: relative;
    margin-left: 105px;
    margin-right: 110px;
    width: 50%;
    margin-top: -2px;

    /* margin: 0 auto; */
  }

  .dropdown-menu {
    position: relative;
    right: 150px;
    width: 150px;
  }

  .dropdown-menu.show {
    position: relative;
    top: 10px;
    right: 105px;
    width: 150px;
  }

  .available-rooms-container {
    display: flex;
    flex-direction: column;
    width: 425px;
  }

  .footer-content {
    margin-top: -5px;
    margin-left: -50px;
    position: static;
  }

  .footer-container {
    position: fixed;
    bottom: -1px;
  }

  .header-title {
    width: 95%;
    font-size: 50px;
    color: transparent;
    -webkit-text-stroke: 0.01px rgba(207, 197, 224, 0.95);
  }

  .weather {
    margin-left: 0;
    width: 200px;
    height: 50px;
    margin-bottom: 320px !important;
  }

  .signature {
    margin-left: 90px !important;
    position: static;
    width: 300px;
    left: 0;
  }

  .email-link {
    position: absolute;
    width: 25px;
    left: 0;
  }

  .email-label {
    position: absolute;
    width: 25px;
    left: 0;
  }

  .phone {
    position: absolute;
    width: 25px;
    left: 0;
  }

  .about-section {
    display: flex;
    flex-direction: column;
    margin-bottom: 0 !important;
  }

  .review-section {
    height: 790px !important;
  }

  .review-wrapper {
    display: flex;
    flex-direction: column !important;
  }

  .review-wrapper .review-card:nth-child(1) {
    width: 76%;
    height: 180px !important;
  }

  .review-wrapper .review-card:nth-child(2) {
    width: 82% !important;
    height: 200px !important;
    margin-bottom: -10px !important;
  }

  .review-wrapper .review-card:nth-child(3) {
    width: 76%;
    height: 180px !important;
  }

  .google-maps-widget {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
  }
}

@media (min-width: 1024px) and (max-width: 1439px) {
  /* style.css */
  .header-title {
    margin-top: 160px;
    flex-grow: 0;
  }

  .signature {
    margin-left: 0;
    width: 895px;
    margin-top: -30px;
    margin-bottom: 60px;
  }

  /* checkavaliliability.css */
  .checkavaliliability {
    margin-top: 35px;
    margin-bottom: 0;
  }

  /* weather.css */
  .weather {
    margin-bottom: 430px;
  }

  /* review.css */
  .review-card:nth-child(2) {
    padding: 15px;
    width: 330px;
    max-width: 360px;
  }

  .review-wrapper .review-card:nth-child(1) {
    height: 240px;
    width: 230px;
    max-width: 240px;
  }

  .review-wrapper .review-card:nth-child(3) {
    height: 240px;
    width: 230px;
    max-width: 240px;
  }

  .date-month {
    font-size: 15px;
  }
}

@media (min-width: 1440px) and (max-width: 2059px) {
  /* style.css */
  .header-title {
    margin-top: 150px;
    flex-grow: 0;
  }

  .signature {
    margin-left: 0;
    width: 895px;
    margin-top: -10px;
  }

  /* checkavaliliability.css */
  .checkavaliliability {
    /* position: static; */
    margin-top: 80px;
    margin-bottom: 0;
  }

  .weather {
    margin-bottom: 375px;
  }

  /* review.css */
  .review-wrapper .review-card:nth-child(1) {
    width: 395px;
    max-width: 395px;
    height: 230px;
  }

  .review-wrapper .review-card:nth-child(2) {
    padding: 10px;
    width: 430px;
    max-width: 430px;
  }

  .review-wrapper .review-card:nth-child(3) {
    width: 395px;
    max-width: 395px;
    height: 230px;
  }
}

@media (min-width: 1024px) and (max-width: 1439px) {
  .col-md-6 .custom-card {
    width: 500px;
  }

  .col-md-6 .cardmedia-card {
    width: 150px;
  }

  /* checkavailiability.css */
  .available-rooms-container {
    width: 900px;
  }

  .available-rooms-container > div {
    margin-bottom: 10px;
    margin-top: 5px;
  }
}

@media (min-width: 1440px) and (max-width: 2559px) {
  .about-section {
    display: flex;
    flex-direction: row;
  }

  .tablet-box {
    display: flex;
    flex-direction: row;
  }
}
