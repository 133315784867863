body {
  margin: 0;
  padding: 0;
  font-family: 'Lato', sans-serif;
}

.header-book-btn-space {
  width: 50px;
  margin-left: 277px;
  right: 63px;
  margin-top: 12px;
  display: flex;
  flex-direction: column;
  position: fixed;
}

.header {
  background-image: url(../images/Naslovna.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
  width: 100%;
  height: 100%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  flex-direction: column;
  min-height: calc(100vh - 50px);
}

.header-title {
  position: relative;
  display: inline-block;
  font-size: 4.5rem;
  font-weight: bold;
  text-align: center;
  font-family: 'Apple Chancery', 'Brush Script MT', cursive;
  color: transparent;
  -webkit-text-stroke: 0.01px rgba(207, 197, 224, 1);
  margin-top: 115px;
  flex-grow: 1;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}

.opera-gx .header-title {
  color: transparent;
  -webkit-text-stroke: 0.01px rgba(207, 197, 224, 1); /* Subtle stroke effect */
}

.signature {
  position: static;
  font-family: 'Pinyon Script', sans-serif;
  color: antiquewhite;
  display: flex;
  flex-direction: row;
  justify-content: right;
  margin-left: auto;
  font-size: 1.7rem;
  text-decoration: underline;
}

.checkavaliliability {
  margin-top: 10px;
  margin-bottom: 30px;
}

.weather {
  position: relative;
  right: auto;
  left: auto;
  margin-top: auto;
  margin-bottom: 290px;
  width: 305px;
  opacity: 1;
  border-radius: 80px;
}

.weather:hover {
  opacity: 1;
}

.review-section {
  margin: 30px;
  position: relative;
  width: auto;
  height: 400px;
  overflow: hidden;
  border-radius: 10px;
  margin-top: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.review-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url(../images/reviews.jpg);
  background-position: center;
  background-size: cover;
  z-index: -1;
}

.wea-rev {
  display: flex;
  flex-direction: row;
  column-gap: 250px;
  margin-top: 70px;
  margin-bottom: 70px;
}

.header-book-btn {
  background-color: #fff;
  border-radius: 5px;
  border: 1px solid rgb(8, 39, 111);
  padding: 1px 7px 1px 15px;
  width: 50px;

  /* margin-top: 50px !important; */
  cursor: pointer;
  margin-top: 24px;
  height: 20px;
}

.room-info {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 30px;
  padding: 0; /* Add horizontal padding */
  overflow: hidden;
}

.room-info-title-box,
.reviews-title-box {
  margin-left: 30px;
  margin-right: 30px;
  margin-top: 30px;
  display: flex;
  justify-content: flex-start;
  font-size: xx-large;
  font-family: 'Lato', sans-serif;
  background: linear-gradient(to right, #17255f, #0f9fce);
  border-radius: 10px;
  color: white;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  align-items: center;
  padding: 1%;
}

.room-info-title-box {
  margin-top: 0;
  position: absolute;
  z-index: 1;
  box-sizing: border-box;
  margin-left: 30px;
  margin-right: 30px;
  width: calc(100% - 60px);
}

.room-info-title,
.reviews-title {
  padding-left: 1%;
}

.logos {
  width: 50px;
  height: auto;
  margin-left: 1%;
  position: relative;
  padding-right: 1%;
}

.separator-room {
  margin-left: 7px;
  margin-right: 7px;
  background-color: white;
  border: none;
  font-size: 30px;
  height: 50px;
  width: 2px;
}

.separator-aboutus {
  margin-left: 3%;
  margin-right: 3%;
  background-color: white;
  border: none;
  font-size: 30px;
  height: 85%;
  width: 2px;
}

.room-photo-box {
  height: 450px;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: auto;
  justify-content: space-evenly;
  position: relative;
  margin: 30px;
  margin-bottom: 0;
  border-radius: 10px;
  gap: 8px;
  background-color: #136296;
  margin-top: 0;
  overflow: hidden;
}

.room-photo1,
.room-photo2 {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  position: relative;
}

.photo1,
.photo2 {
  border-radius: 0;
  width: 100%;
}

.photo1 {
  border-bottom-left-radius: 10px;
  border-top-left-radius: 10px;
}

.photo2 {
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
}

.photo-text1,
.photo-text2 {
  font-size: 3vw;
  position: absolute;
  background-color: #0f9fce8f;
  display: flex;
  justify-content: center;
  height: 20%;
  align-items: center;
  width: 100%;
  font-family: 'Lato', sans-serif;
  color: white;
}

.photo-text1 {
  border-bottom-left-radius: 10px;
}

.photo-text2 {
  border-bottom-right-radius: 10px;
}

.room-photo1 img,
.room-photo2 img {
  transition: filter 0.3s ease; /* Smooth transition for the blur effect */
}

.room-photo1 img:hover,
.room-photo2 img:hover {
  filter: blur(2px); /* Adjust the blur radius as needed */
}

.view-more {
  position: absolute;
  bottom: 50%;
  border: none;
  padding: 2px 5px;
  width: 125px;
  height: 42px;
  color: rgb(255, 255, 255);
  background-color: #0f9fce8f;
  font-size: 12px;
  font-weight: bold;
  border-radius: 16px;
  transition: background-color 0.3s, color 0.3s, border-color 0.3s;
  cursor: pointer;
  display: none;
}

.room-photo1:hover .view-more,
.room-photo2:hover .view-more {
  display: block; /* Show the button when the image is hovered */
}

.about-us-box {
  height: auto;
  background: url('../images/about\ us.jpeg') no-repeat bottom center;
  background-size: cover;
  background-position: bottom center;
  background-repeat: no-repeat;
}

.about-us {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: linear-gradient(to top, rgba(10, 61, 98, 0) 0%, #0a3d62 62%);
}

.about-us-text {
  display: flex;
  font-family: 'Lato', sans-serif;
  color: white;
  text-indent: 2em;
  box-sizing: border-box;
  line-height: 1.4;
  margin-left: 6%;
  margin-right: 6%;
}

.logosau {
  position: absolute;
  left: 0;
  top: 0;
  width: 58px;
  background: #0f254eba;
  padding: 1%;
  border-bottom-right-radius: 22px;
}

.au-title {
  display: flex;
  color: white;
  font-size: xxx-large;
  font-family: 'Apple Chancery', 'Brush Script MT', cursive;
  line-height: 0.8;
}

.separator-au {
  width: 75%;
  height: 2px;
  background-color: white;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.header-book-btn-signup {
  color: white;
  border-radius: 5px;
  border: 1px solid rgb(8, 39, 111);
  padding: 1px 7px 1px 15px;
  background-color: rgb(8, 39, 111);
  width: 50px;
  cursor: pointer;
  margin-top: 24px;
  height: 20px;
}

.book-page {
  position: relative;
  width: auto;
  height: 700px;
  overflow: hidden;
}

.book-page::before {
  content: '';
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url(../images/book.jpg);
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
  z-index: -1;
}

.contacts {
  position: fixed;
  display: flex;
  margin-left: 25%;
  margin-right: 25%;
  flex-direction: row;
  align-items: center;
  width: 50%;
  height: 68px;
  justify-content: center;
}

.phone,
.email {
  display: flex;
  background-color: rgb(245, 245, 245, 0);
  align-items: center;
  margin-right: 15px;
}

.phone-link {
  text-decoration: none;
  font-size: 13px;
  font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
  font-weight: bold;
  color: #1877f2;
}

.email-link {
  text-decoration: none;
  font-size: 15px;
  font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
  font-weight: bold;
  color: #1877f2;
}

.phone {
  margin-left: 5px;
  font-size: 15px;
  font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
  font-weight: bold;
  color: #1877f2;
}

.email {
  margin-left: 5px;
  margin-bottom: 15px;
  margin-top: 14px;
  margin-right: 40px;
  font-size: 15px;
  font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
  font-weight: bold;
  color: #8c4848;
}

.phone-icon,
.envelope-icon {
  color: #fc962b;
  font-size: 15px;
  margin-right: 5px;
}

.phone:hover .phone-icon {
  transform: translateY(-0.1px);
}

.phone:hover {
  transform: translateY(-2px);
}

.email:hover .envelope-icon {
  transform: translateY(-0.1px);
}

.email:hover {
  transform: translateY(-2px);
}

.location-icon {
  color: #db4437;
  font-size: 24px;
  height: 30px;
  width: 35px;
}

.location-icon:hover {
  transform: translateY(-2px);
}

.facebook-icon {
  color: #156ddf;
  font-size: 24px;
}

.facebook-icon:hover {
  transform: translateY(-2px);
}

.footer-container {
  background-color: #17255f;
  bottom: 0;
  color: #fff;
  font-size: 14px;
  height: 5px;
  padding: 20px;
  position: fixed;
  text-align: center;
  width: 100%;
  z-index: 2;
}

.footer-content {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.footer-content p {
  margin: 0;
}

.footer-logo {
  height: 30px;
  margin-bottom: -6px;
  width: 30px;
  margin-right: 15px;
}

.facebook1-icon {
  color: #faf1a0;
  font-size: 20px;
  margin-left: 15px;
}

.instagram-icon {
  color: #fc962b;
  font-size: 20px;
  margin-left: 15px;
}

.content-wrapper {
  background-color: white;
  padding: 20px;
}

body::-webkit-scrollbar {
  width: 16px;
}

body::-webkit-scrollbar-track {
  border-radius: 0;
  background-color: #eee0e0;
}

body::-webkit-scrollbar-thumb {
  border-radius: 8px;
  border: 3px solid transparent;
  background-clip: content-box;
  background-color: #7da7d5;
}

.Maindash::-webkit-scrollbar {
  width: 16px;
}

.Maindash::-webkit-scrollbar-track {
  border-radius: 0;
  background-color: #eee0e0;
}

.Maindash::-webkit-scrollbar-thumb {
  border-radius: 8px;
  border: 3px solid transparent;
  background-clip: content-box;
  background-color: #7da7d5;
}

.content-section {
  background-color: #fff;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  position: static;
  padding: 0;
  margin-top: 25px;
  margin-bottom: 70px;
}

.room-availability {
  width: 100%;
  max-width: max-content;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.col-md-6 {
  width: calc(50% - 5px);
  margin-bottom: 10px;
  box-sizing: border-box;
}

.header-myreservations {
  margin-top: 20px;
}

.my-resevations {
  font-size: 10px;
  text-decoration: none;
}

.header-edit {
  margin-top: 20px;
}

.edit {
  font-size: 10px;
  text-decoration: none;
}

.facebook-nav {
  width: auto;
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .header {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    position: static;
    width: 100%;
    height: 20%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: column;
    min-height: calc(90vh - 50px);
  }

  .header-book-btn-space {
    width: auto;
    position: fixed;
    right: 0;
    margin-left: auto;
    margin-right: 5px;
    padding: 0 10px;
  }

  .header-title {
    font-size: 3rem;
    margin-top: 145px;
  }

  .checkavaliliability {
    position: static;
    margin-bottom: -20px;
  }

  .weather {
    margin-top: 50px;
    height: 75px;
    position: static;
    margin-bottom: 290px;
  }

  .review-section {
    height: auto;
  }

  .review-background {
    background-image: url(../images/reviews.jpg);
  }

  .wea-rev {
    flex-direction: column;
    column-gap: 0;
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .contacts {
    position: fixed;
    display: flex;
    margin-left: 17%;
    margin-right: 27%;
    width: 60%;
  }

  .phone,
  .email,
  .location,
  .facebook {
    display: flex;
    justify-content: center;
    margin-right: 25px;
    text-align: center;
  }

  .facebook {
    margin-left: 20px;
    margin-right: auto;
  }

  .phone-icon,
  .envelope-icon,
  .location-icon,
  .facebook-icon {
    margin-right: 6px;
  }

  .footer-container {
    height: auto;
    padding: 8px;
  }

  .footer-content {
    flex-direction: column;
    align-items: center;
  }

  .footer-content p {
    margin-bottom: 10px;
  }

  .footer-logo {
    margin-right: 10px;
  }

  .footer-icon {
    margin: 0;
  }

  .tablet-box {
    display: flex;
    flex-direction: row !important;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 10px;
    margin-top: 15px;
    flex-wrap: nowrap;
  }

  .about-section {
    display: flex;
    flex-direction: column !important;
  }

  .about-logo {
    margin-left: -35px !important;
    margin-right: 40px !important;
  }

  .about-text {
    margin-left: -120px !important;
    width: 400px !important;
  }

  .rooms,
  .services {
    width: 200px;
    flex: 1 1 calc(50% - 10px);
    margin-bottom: 20px;
  }

  .content-section {
    margin-top: 10px;
    margin-bottom: 30px;
  }

  .room-availability {
    width: 100%;
    max-width: 100%;
    flex-direction: column;
    align-items: center;
  }

  .col-md-6 {
    width: 100%;
    margin-bottom: 20px;
  }

  .header-myreservations,
  .header-edit {
    margin-top: 10px;
  }

  .my-resevations,
  .edit {
    font-size: 12px;
    text-align: center;
  }
}

@media only screen and (min-width: 320px) and (max-width: 424px) {
  .section-1 {
    width: auto;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  .header-book-btn-space {
    width: 100%;
    margin: 0 auto;
    padding: 0 5px;
  }

  .header-title {
    font-size: 3rem;
    text-align: center;
  }

  .weather {
    margin-bottom: 170px;
  }

  .room-info-title-box,
  .reviews-title-box {
    border-radius: 10px;
    font-size: large;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .logos {
    width: 30px;
  }

  .separator-room {
    height: 30px;
    width: 1px;
  }

  .room-photo-box {
    height: auto;
    flex-direction: column;
    gap: 0;
    border-radius: 0;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  .room-photo1,
  .room-photo2 {
    justify-content: center;
  }

  .room-info {
    border-radius: 0;
    flex-direction: column;
    align-items: center;
    align-items: flex-start;
  }

  .view-more {
    bottom: unset;
    left: unset;
  }

  .photo-text1,
  .photo-text2 {
    border-radius: 0;
    height: 10%;
    font-size: large;
    left: 0;
  }

  .photo-text1 {
    bottom: 50%;
  }

  .photo-text2 {
    bottom: 0;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }

  .photo1 {
    border-radius: 0;
  }

  .photo2 {
    border-radius: 0;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }

  .au-title {
    margin-top: 14%;
    margin-bottom: 2%;
  }

  .about-us-text {
    margin-bottom: 53%;
    margin-top: 2%;
  }

  .review-section {
    width: auto;
    height: auto;
  }

  .review-background {
    background-image: url(../images/reviews.jpg);
  }

  .wea-rev {
    flex-direction: column;
    column-gap: 0;
    margin-top: 30px;
    margin-bottom: 30px;
    align-items: center;
  }

  .phone,
  .email,
  .location,
  .facebook {
    margin: 0 auto;
    text-align: center;
  }

  .phone-icon,
  .envelope-icon,
  .location-icon,
  .facebook-icon {
    margin-right: 6px;
  }

  .footer-container {
    width: 359px;
    height: auto;
    padding-top: 8px;
    padding-bottom: 8px;
  }

  .footer-content {
    flex-direction: column;
    align-items: center;
    width: auto;
  }

  .footer-content p {
    margin-bottom: 10px;
  }

  .footer-logo {
    margin-right: 6px;
  }

  .facebook1-icon {
    margin-left: 8px;
  }

  .footer-icon {
    margin: 0;
  }

  .content-section {
    margin-top: 10px;
    margin-bottom: 30px;
  }

  .room-availability {
    width: 100%;
    max-width: 100%;
    flex-direction: column;
    align-items: center;
  }

  .col-md-6 {
    width: 100%;
    margin-bottom: 20px;
  }

  .header-myreservations,
  .header-edit {
    margin-top: 10px;
    text-align: center;
  }

  .my-resevations,
  .edit {
    font-size: 12px;
    text-align: center;
  }

  .about-us-box {
    background-size: 150% 100%;
  }

  .checkavaliliability {
    display: flex;
    flex-direction: column;
  }

  .container {
    width: auto;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
  }

  .phone-link {
    opacity: 0;
    margin-right: -15px;
  }

  .email-link {
    opacity: 0;
    margin-right: -20px;
    width: 30px;
  }

  .location {
    margin-right: 44px;
  }

  .facebook-icon {
    margin-left: 14px;
  }
}

@media only screen and (max-width: 1024px) {
  .room-info-title-box {
    position: relative;
    z-index: 0;
  }

  .room-info {
    border-radius: 0;
    flex-direction: column;
    align-items: center;
    align-items: flex-start;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }

  .view-more {
    bottom: unset;
    left: unset;
  }

  .logos {
    width: 30px;
  }

  .separator-room {
    height: 30px;
    width: 1px;
  }

  .room-photo-box {
    height: auto;
    flex-direction: column;
    gap: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  .room-photo1,
  .room-photo2 {
    justify-content: center;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }

  .photo-text1,
  .photo-text2 {
    border-radius: 0;
    height: 10%;
    font-weight: 400;
    line-height: 1.6;
    font-size: large;
    left: 0;
  }

  .photo-text1 {
    border-radius: 0;
    bottom: 0;
  }

  .photo-text2 {
    bottom: 0;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }

  .photo1 {
    border-radius: 0;
  }

  .photo2 {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
}

@media only screen and (min-width: 1440px) {
  .about-us-text {
    font-size: x-large;
    margin-top: 4%;
    margin-bottom: 14%;
  }

  .au-title {
    margin-top: 5%;
    margin-bottom: 1%;
  }
}

@media only screen and (min-width: 1024px) {
  .au-title {
    margin-top: 5%;
    margin-bottom: 1%;
  }

  .about-us-text {
    margin-bottom: 27%;
    margin-top: 2%;
    font-size: x-large;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .au-title {
    margin-top: 8%;
    margin-bottom: 1%;
  }

  .about-us-text {
    margin-top: 3%;
    margin-bottom: 28%;
  }
}

@media only screen and (min-width: 425px) and (max-width: 768px) {
  .room-info-title-box,
  .reviews-title-box {
    font-size: x-large;
  }

  .au-title {
    margin-top: 10%;
    margin-bottom: 2%;
  }

  .about-us-text {
    margin-top: 4%;
    margin-bottom: 42%;
  }
}

@media only screen and (min-width: 1024px) {
  .au-title {
    margin-top: 5%;
    margin-bottom: 1%;
  }

  .about-us-text {
    margin-bottom: 22%;
    margin-top: 2%;
    font-size: x-large;
  }
}
